/**
 * Clears the filter values in the local storage for the specified table memory.
 *
 * @param {string} tableMemoryName - The name of the table memory in local storage.
 * @returns {void}
 */
export function clearTableMemoryFilters(tableMemoryName: string): void {
  const memory = localStorage.getItem(tableMemoryName);

  if (!memory) {
    return; // Exit if no table data was found in local storage
  }

  try {
    // Parse the memory object
    const { columnWidths, first, filters, rows, tableWidth } =
      JSON.parse(memory);

    if (filters && typeof filters === 'object') {
      // Iterate over each filter and clear the 'value' property if it exists
      Object.keys(filters).forEach((key) => {
        if (
          Reflect.apply(Object.prototype.hasOwnProperty, filters[key], [
            'value'
          ])
        ) {
          filters[key].value = null;
        }
      });
    }

    // Update local storage with cleared filters
    localStorage.setItem(
      tableMemoryName,
      JSON.stringify({ columnWidths, filters, first, rows, tableWidth })
    );
  } catch (error) {
    console.error(
      `Failed to parse or update table memory for table: ${tableMemoryName}:`,
      error
    );
  }
}

interface Filter {
  value?: string | null;
}

/**
 * Checks if any filter in the local storage for the specified table memory has a set value.
 *
 * @param {string} tableMemoryName - The name of the table memory in local storage.
 * @returns {boolean} - Returns true if at least one filter has a value, otherwise false.
 */
export function hasActiveFilters(tableMemoryName: string): boolean {
  const memory = localStorage.getItem(tableMemoryName);

  if (!memory) {
    return false; // No table data found
  }

  try {
    // Parse the memory object
    const { filters }: { filters: Record<string, Filter> } = JSON.parse(memory);

    if (filters && typeof filters === 'object') {
      return Object.values(filters).some(
        (filter) =>
          filter &&
          'value' in filter &&
          filter.value !== null &&
          filter.value !== ''
      );
    }
  } catch (error) {
    console.error(
      `Failed to parse table memory for table: ${tableMemoryName}:`,
      error
    );
  }

  return false; // Default return if an error occurs
}
