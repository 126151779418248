<p-table
  #tableElement
  dataKey="id"
  [rows]="tableConfig.rows"
  [scrollHeight]="tableConfig.scrollHeight"
  [filterDelay]="1"
  [(selection)]="selectedDevices"
  (selectionChange)="selectedDevicesChange.emit($event)"
  [value]="virtualCustomerDevices"
  [paginator]="true"
  [resizableColumns]="true"
  columnResizeMode="expand"
  [showCurrentPageReport]="true"
  [currentPageReportTemplate]="'table.pageReportTemplate' | translate"
  [globalFilterFields]="['virtual.manufacturer', 'virtual.device', 'virtual.commissionType', 'virtual.commissionDate', 'virtual.controlUnit', 'virtual.heatingEngineer', 'virtual.serialNumbers', 'note', 'virtual.controlUnit', 'virtual.isGasSeparatorInstalled' ,'isActive']"
  [rowsPerPageOptions]="tableConfig.rowsPerPageOptions"
  [scrollable]="true">
  <ng-template pTemplate="caption">
    <div class="flex relative align-items-center justify-content-between">
      <div>
        <span class="p-input-icon-left ml-auto">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            [value]="globalSearchValue"
            (input)="tableElement.filterGlobal($any($event.target).value, 'contains')"
            [placeholder]="'general.globalSearchPlaceholder' | translate" />
        </span>
      </div>
      <div *ngIf="tableConfig.showTableHeader" class="title-container">
        <h4>{{ "customerDeviceComponent.table.title" | translate}}</h4>
      </div>
      <div *ngIf="$can('create') && showCreateButton">
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-plus-circle"
          [label]="'customerDeviceComponent.newDevice' | translate"
          class="flex-auto p-button-outlined"
          (click)="createCustomerDevice()"></button>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th style="width: 4rem" *ngIf="selectionEnabled">
        <p-tableHeaderCheckbox />
      </th>

      <th
        pResizableColumn
        pSortableColumn="virtual.manufacturer"
        class="white-space-nowrap"
        alignFrozen="left"
        pFrozenColumn>
        {{ 'customerDeviceComponent.table.columns.manufacturer.title' |
        translate }}
        <p-sortIcon field="virtual.manufacturer" />
      </th>

      <th
        pResizableColumn
        pSortableColumn="virtual.device"
        class="white-space-nowrap"
        alignFrozen="left"
        pFrozenColumn>
        {{ 'customerDeviceComponent.table.columns.device.title' | translate }}
        <p-sortIcon field="virtual.device" />
      </th>

      <th
        pResizableColumn
        pSortableColumn="virtual.serialNumbers"
        class="white-space-nowrap">
        {{ 'customerDeviceComponent.table.columns.serialNumbers.title' |
        translate }}
        <p-sortIcon field="virtual.serialNumbers" />
      </th>

      <th
        *ngIf="!isTicketView"
        pResizableColumn
        pSortableColumn="controlUnit"
        class="white-space-nowrap">
        {{ 'customerDeviceComponent.table.columns.controlUnitId.title' |
        translate }}
        <p-sortIcon field="controlUnit" />
      </th>

      <th
        *ngIf="!isTicketView"
        pResizableColumn
        pSortableColumn="isGasSeparatorInstalled"
        class="white-space-nowrap">
        {{ 'customerDeviceComponent.table.columns.isGasSeparatorInstalled.title'
        | translate }}
        <p-sortIcon field="isGasSeparatorInstalled" />
      </th>

      <th
        *ngIf="!isTicketView"
        pResizableColumn
        pSortableColumn="virtual.heatingEngineer"
        class="white-space-nowrap">
        {{ 'customerDeviceComponent.table.columns.heatingEngineer.title' |
        translate }}
        <p-sortIcon field="virtual.heatingEngineer" />
      </th>

      <th
        *ngIf="!isTicketView"
        pResizableColumn
        pSortableColumn="virtual.commission"
        class="white-space-nowrap">
        {{ 'customerDeviceComponent.table.columns.commission.title' | translate
        }}
        <p-sortIcon field="virtual.commission" />
      </th>

      <th
        pResizableColumn
        pSortableColumn="virtual.commissionDate"
        class="white-space-nowrap">
        {{ 'customerDeviceComponent.table.columns.commissionDate.title' |
        translate }}
        <p-sortIcon field="virtual.commissionDate" />
      </th>

      <th
        *ngIf="!isTicketView"
        pResizableColumn
        pSortableColumn="note"
        class="white-space-nowrap">
        {{ 'customerDeviceComponent.table.columns.note.title' | translate }}
        <p-sortIcon field="note" />
      </th>

      <th
        *ngIf="!isTicketView"
        pResizableColumn
        pSortableColumn="isActive"
        class="white-space-nowrap">
        {{ 'customerDeviceComponent.table.columns.isActive.titleShort' |
        translate }}
        <p-sortIcon field="isActive" />
      </th>

      <th alignFrozen="right" pFrozenColumn>
        {{ 'table.actionsTitle' | translate}}
      </th>
    </tr>

    <tr>
      <th *ngIf="selectionEnabled"></th>
      <th alignFrozen="left" pFrozenColumn>
        <p-columnFilter
          field="virtual.manufacturer"
          matchMode="contains"
          [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <input
              type="text"
              pInputText
              [ngModel]="value"
              (ngModelChange)="filter($event)"
              style="min-width: 10rem"
              class="p-inputtext" />
          </ng-template>
        </p-columnFilter>
      </th>
      <th alignFrozen="left" pFrozenColumn>
        <p-columnFilter
          field="virtual.device"
          matchMode="contains"
          [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <input
              type="text"
              pInputText
              [ngModel]="value"
              (ngModelChange)="filter($event)"
              style="min-width: 15rem"
              class="p-inputtext" />
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          field="virtual.serialNumbers"
          matchMode="contains"
          [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <input
              type="text"
              pInputText
              [ngModel]="value"
              (ngModelChange)="filter($event)"
              style="min-width: 7rem"
              class="p-inputtext" />
          </ng-template>
        </p-columnFilter>
      </th>

      <th *ngIf="!isTicketView" alignFrozen="left">
        <p-columnFilter
          field="virtual.controlUnit"
          matchMode="contains"
          [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <input
              type="text"
              pInputText
              [ngModel]="value"
              (ngModelChange)="filter($event)"
              style="min-width: 7rem"
              class="p-inputtext" />
          </ng-template>
        </p-columnFilter>
      </th>

      <th *ngIf="!isTicketView">
        <p-columnFilter
          type="boolean"
          matchMode="equals"
          field="isGasSeparatorInstalled" />
      </th>

      <th *ngIf="!isTicketView" alignFrozen="left">
        <p-columnFilter
          field="virtual.heatingEngineer"
          matchMode="contains"
          [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <input
              type="text"
              pInputText
              [ngModel]="value"
              (ngModelChange)="filter($event)"
              style="min-width: 7rem"
              class="p-inputtext" />
          </ng-template>
        </p-columnFilter>
      </th>
      <th *ngIf="!isTicketView">
        <p-columnFilter
          field="commissionType"
          matchMode="in"
          [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
              [appendTo]="'body'"
              [ngModel]="value"
              [options]="commissionTypeOptions"
              (onChange)="filter($event.value)"
              [style]="{ width: '120px' }"
              optionLabel="label"
              optionValue="value">
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          field="virtual.commissionDate"
          matchMode="date"
          [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-calendar
              [showTime]="false"
              [dateFormat]="'dd.mm.yy'"
              [ngModel]="value"
              (ngModelChange)="filter($event)"
              appendTo="body"
              selectionMode="range"
              [readonlyInput]="true"
              [style]="{ width: '12rem' }" />
          </ng-template>
        </p-columnFilter>
      </th>
      <th *ngIf="!isTicketView">
        <p-columnFilter field="note" matchMode="contains" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <input
              type="text"
              pInputText
              [ngModel]="value"
              (ngModelChange)="filter($event)"
              style="min-width: 7rem"
              class="p-inputtext" />
          </ng-template>
        </p-columnFilter>
      </th>
      <th *ngIf="!isTicketView">
        <p-columnFilter type="boolean" matchMode="equals" field="isActive" />
      </th>
      <th alignFrozen="right" pFrozenColumn>
        <div class="flex gap-2">
          <button
            pButton
            pRipple
            [pTooltip]="'general.tooltips.manageFilters' | translate"
            tooltipPosition="left"
            (click)="filterManagement.toggle($event)"
            [outlined]="true"
            [style]="'border-radius: 50%; width: 2rem !important;height: 2rem!important;'"
            icon="pi pi-bars"></button>
          <button
            pButton
            pRipple
            [pTooltip]="'general.tooltips.saveFilters' | translate"
            tooltipPosition="left"
            [outlined]="true"
            [style]="'border-radius: 50%; width: 2rem !important;height: 2rem!important;'"
            (click)="filterCreate.toggle($event)"
            icon="pi pi-save"></button>
          <button
            pButton
            pRipple
            [pTooltip]="'general.tooltips.removeFilter' | translate"
            tooltipPosition="left"
            [style]="'border-radius: 50%; width: 2rem !important;height: 2rem!important;'"
            (click)="clearFilters()"
            [ngClass]="{'p-button-outlined': !isFilterApplied}"
            icon="pi pi-filter-slash"></button>
        </div>
        <p-overlayPanel
          #filterManagement
          (onShow)="displayFilterOverlay = $event">
          <app-filter
            #appFilter
            table="customer-device"
            (filterApplied)="filterApplied($event)"></app-filter>
        </p-overlayPanel>
        <p-overlayPanel #filterCreate>
          <app-filter-create
            (saveFilterClicked)="saveFilterClicked($event);filterCreate.toggle($event)"></app-filter-create>
        </p-overlayPanel>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-customerDevice>
    <tr [ngClass]="{'deleted-data': customerDevice.deletedAt}">
      <td *ngIf="selectionEnabled">
        <p-tableCheckbox [value]="customerDevice" />
      </td>
      <td alignFrozen="left" pFrozenColumn>
        <span class="white-space-nowrap">
          {{ customerDevice.virtual.manufacturer }}
        </span>
      </td>

      <td alignFrozen="left" pFrozenColumn>
        <span
          class="white-space-nowrap"
          [ngClass]="{'deleted-data': customerDevice?.device?.deletedAt}">
          {{ customerDevice.virtual.device }}
        </span>
      </td>

      <td>
        <span class="white-space-nowrap">
          {{customerDevice.virtual.serialNumbers}}
        </span>
      </td>

      <td *ngIf="!isTicketView">
        <span class="white-space-nowrap">
          {{ customerDevice.virtual.controlUnit }}
        </span>
      </td>

      <td *ngIf="!isTicketView">
        <span class="white-space-nowrap">
          {{ customerDevice.virtual.isGasSeparatorInstalled }}
        </span>
      </td>

      <td *ngIf="!isTicketView">
        <span class="white-space-nowrap flex gap-2">
          <div
            class="flex align-items-center justify-content-center"
            [ngStyle]="{
              'background-color': customerDevice.virtual.heatingEngineerIsBlocked ?'#FF0000':'#7AAA00',
              'border-radius': '1.5rem',
              width: '1.5rem',
              height: '1.5rem',
              color: '#f1f1f1'
            }">
            <i
              *ngIf="!customerDevice.virtual.heatingEngineerIsBlocked"
              class="pi pi-check text-xs"></i>
            <i
              *ngIf="customerDevice.virtual.heatingEngineerIsBlocked"
              class="pi pi-times text-xs"
              [ngStyle]="{
        marginTop: '0.12rem'
      }">
            </i>
          </div>
          {{customerDevice.virtual.heatingEngineer}}
        </span>
      </td>

      <td *ngIf="!isTicketView">
        <span class="white-space-nowrap">
          {{customerDevice.virtual.commissionType}}
        </span>
      </td>
      <td>
        <span class="white-space-nowrap">
          {{ customerDevice.virtual.commissionDate }}
        </span>
      </td>
      <td *ngIf="!isTicketView">
        <span [pTooltip]="customerDevice.note" class="cut-text">
          {{ customerDevice.note }}
        </span>
      </td>
      <td *ngIf="!isTicketView">
        <p-inputSwitch
          [disabled]="!$can('update')"
          [accessKey]="customerDevice.id + '-isActive'"
          [ngModel]="customerDevice.isActive"
          (ngModelChange)="changeIsActive($event, customerDevice)" />
      </td>
      <td alignFrozen="right" pFrozenColumn>
        <div class="flex gap-2">
          <app-table-action
            *ngIf="$can('update') && showEditIcon"
            (actionClicked)="updateCustomerDevice(customerDevice)"
            [icon]="'pi pi-pencil'"
            [severity]="'success'"
            [pTooltip]="'customerDeviceComponent.actions.tooltips.edit' | translate"></app-table-action>

          <app-table-action
            *ngIf="$can('read') && !$can('update') && showEditIcon"
            (actionClicked)="viewCustomerDevice(customerDevice)"
            [icon]="'pi pi-eye'"
            [severity]="'info'"
            [pTooltip]="'customerDeviceComponent.actions.tooltips.view' | translate"></app-table-action>

          <app-table-action
            *ngIf="$can('delete') && showDeleteIcon"
            (actionClicked)="deleteCustomerDevice(customerDevice)"
            [icon]="'pi pi-trash'"
            [severity]="'danger'"
            [pTooltip]="'customerDeviceComponent.actions.tooltips.delete' | translate"></app-table-action>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
