<div class="ticket-summary-container" [ngClass]="colorScheme">
  <div
    class="ticket-summary-header"
    [style]="{backgroundColor: backgroundColor, color: '#fff'}">
    <span [class]="'pi '+icon"></span
    ><span class="ml-2"
      >{{'ticketComponent.ticketChips.'+ticket.ticketCategoryType |
      translate}}</span
    >
  </div>
  <div class="ticket-summary-body pr-4 pl-4">
    <div class="ticket-number text-sm flex">
      <span class="text-primary pr-2 pb-2">{{ ticket.ticketNumber }}</span>
      <a
        (click)="stopPropagation($event)"
        [routerLink]="'/tickets/' + ticket.id"
        target="_blank"
        class="pi pi-external-link link text-primary"></a>
    </div>
    <div class="flex justify-content-between">
      <div class="font-bold pb-1">
        <app-expandable-text
          [text]="ticket.subject"
          [init]="expanded"
          [smallLength]="40"
          [midLength]="100"></app-expandable-text>
      </div>

      <div class="flex gap-1 justify-content-end">
        <div
          (click)="appointmentPanel.toggle($event)"
          (click)="preventClick($event)"
          class="p-d-flex p-ai-center logging-view-preview-container"
          *ngIf="ticket.appointments && ticket.appointments.length > 0">
          <i
            class="pi pi-calendar p-overlay-badge text-primary"
            style="font-size: 1rem">
            <span class="p-badge">{{ticket.appointments.length}}</span>
          </i>
        </div>
      </div>
    </div>
    <div *ngIf="ticket.requestReason">
      <div class="text-primary text-sm pt-2">
        {{'ticketComponent.attributes.requestReason' | translate}}
      </div>
      <div [ngClass]="{'expanded': expanded}" class="ticket-reason pt-2 pb-2">
        <app-expandable-text
          [text]="ticket.requestReason"
          [init]="expanded"
          [smallLength]="100"
          [midLength]="200"></app-expandable-text>
      </div>
    </div>
    <div *ngIf="ticket.description">
      <div class="text-primary text-sm">
        {{'ticketComponent.attributes.description' | translate}}
      </div>
      <div
        [ngClass]="{'expanded': expanded}"
        class="ticket-description pt-2 pb-2">
        <app-expandable-text
          [text]="ticket.description"
          [init]="expanded"
          [smallLength]="100"
          [midLength]="200"></app-expandable-text>
      </div>
    </div>

    <hr *ngIf="ticket.customer" />
    <div [ngClass]="{'flex justify-content-between':!expanded }">
      <div *ngIf="ticket.customer" class="customer-container pb-2">
        <div class="flex">
          <span class="text-primary pr-2"> {{ ticket.customer.name}} </span>
          <a
            *ngIf="ticket.customer"
            (click)="stopPropagation($event)"
            [routerLink]="'/customers/' + ticket.customerId"
            target="_blank"
            class="pi pi-external-link link text-primary"></a>
        </div>
        <div>
          <span> {{ ticket.customer.customerNumber}} </span>
        </div>
        <div *ngIf="ticket.customer.facilityAddress">
          <span>
            {{ formatAddress(ticket.customer.facilityAddress, 'street')}}
          </span>
        </div>
        <div
          *ngIf="ticket.customer.facilityAddress"
          class="flex justify-content-between">
          <div>
            <span>
              {{ formatAddress(ticket.customer.facilityAddress, 'postal')}}
            </span>
          </div>
          <div class="flex gap-1 pl-2 justify-content-end">
            <span
              *ngIf="ticket.customer && ticket.customer.facilityAddress"
              class="pi pi-map text-primary"
              [pTooltip]="'ticketComponent.summary.tooltips.maps' | translate"
              [tooltipPosition]="'left'"
              (click)="openGoogleMaps($event, true)"></span>
            <span
              *ngIf="ticket.customer && ticket.customer.facilityAddress"
              class="pi pi-copy text-primary"
              [pTooltip]="'ticketComponent.summary.tooltips.copyAdress' | translate"
              [tooltipPosition]="'left'"
              (click)="openGoogleMaps($event,false)"></span>
          </div>
        </div>
      </div>
      <div
        *ngIf="ticket.customerContactPerson"
        class="customer-container"
        [ngClass]="{'flex gap-2 justify-content-between': expanded}">
        <div>
          <span class="text-primary" [ngClass]="{'pb-2': !expanded}">
            {{ ticket.customerContactPerson.firstname }} {{
            ticket.customerContactPerson.lastname }}
          </span>
          <a
            *ngIf="ticket.customer"
            (click)="stopPropagation($event)"
            [routerLink]="'/customers/' + ticket.customerId "
            [queryParams]="{ tab: 1 }"
            target="_blank"
            class="pi pi-external-link link text-primary"></a>
        </div>
        <div [ngClass]="{'flex gap-2': expanded}">
          <div *ngIf="ticket.customerContactPerson.phoneNumberLandline">
            <a
              (click)="stopPropagation($event)"
              [href]="'tel:' + ticket.customerContactPerson.phoneNumberLandline">
              <span
                class="pi pi-phone text-primary"
                [tooltipPosition]="'left'"
                [pTooltip]="'ticketComponent.summary.tooltips.landline' | translate"></span>
            </a>
            <span *ngIf="!expanded">
              {{ ticket.customerContactPerson.phoneNumberLandline}}
            </span>
          </div>
          <div *ngIf="ticket.customerContactPerson.phoneNumberMobile">
            <a
              (click)="stopPropagation($event)"
              [href]="'tel:' + ticket.customerContactPerson.phoneNumberMobile">
              <span
                class="pi pi-mobile text-primary"
                [tooltipPosition]="'left'"
                [pTooltip]="'ticketComponent.summary.tooltips.mobile' | translate"></span>
            </a>
            <span *ngIf="!expanded">
              {{ ticket.customerContactPerson.phoneNumberMobile}}
            </span>
          </div>
          <div *ngIf="ticket.customerContactPerson.email">
            <a
              (click)="stopPropagation($event)"
              [href]="'mailto:' + ticket.customerContactPerson.email">
              <span
                class="pi pi-envelope text-primary"
                [tooltipPosition]="'left'"
                [pTooltip]="'ticketComponent.summary.tooltips.email' | translate"></span>
            </a>
            <span *ngIf="!expanded">
              {{ ticket.customerContactPerson.email}}
            </span>
          </div>
        </div>
      </div>
    </div>
    <hr *ngIf="ticket.customerDevices && ticket.customerDevices.length > 0" />
    <div
      *ngIf="ticket.customerDevices && ticket.customerDevices.length > 0 && expanded">
      <div>
        <span class="text-primary pb-4 pr-2"
          >{{'ticketComponent.attributes.devices' | translate}}</span
        >
        <a
          *ngIf="ticket.customer"
          (click)="stopPropagation($event)"
          [routerLink]="'/customers/' + ticket.customerId "
          [queryParams]="{ tab: 2 }"
          target="_blank"
          class="pi pi-external-link link text-primary"></a>
      </div>
      <div *ngFor="let device of ticket.customerDevices">
        <div>
          <span>{{device.device?.title}}</span
          ><span *ngIf="device.commissionDate">
            ({{device.commissionDate | date: 'dd.MM.yyyy'}})</span
          >
        </div>
      </div>
    </div>
    <div
      *ngIf="ticket.customerDevices && ticket.customerDevices.length > 0 && !expanded">
      <p-table
        *ngIf="ticket.customerDevices && !expanded"
        [value]="ticket.customerDevices"
        [styleClass]="'p-datatable-sm'"
        responsiveLayout="scroll">
        <ng-template pTemplate="header">
          <tr>
            <th class="font-bold">
              <div class="flex gap-2">
                <div class="text-primary">
                  {{'ticketComponent.attributes.device' | translate}}
                </div>
                <div>
                  <a
                    *ngIf="ticket.customer"
                    (click)="stopPropagation($event)"
                    [routerLink]="'/customers/' + ticket.customerId "
                    [queryParams]="{ tab: 2 }"
                    target="_blank"
                    class="pi pi-external-link link text-primary"></a>
                </div>
              </div>
            </th>
            <th>{{'ticketComponent.attributes.serialnumbers' | translate}}</th>
            <th>{{'ticketComponent.attributes.commissionDate' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-device>
          <tr>
            <td>
              <div>
                <div>{{device.device?.manufacturer?.name}}</div>
                <div>{{device.device?.title}}</div>
              </div>
            </td>
            <td>
              <div>
                <div *ngIf="device.internalDeviceSerialNumber">
                  {{'ticketComponent.attributes.ig' | translate}}
                  {{device.internalDeviceSerialNumber}}
                </div>
                <div *ngIf="device.externalDeviceSerialNumber">
                  {{'ticketComponent.attributes.ag' | translate}}
                  {{device.externalDeviceSerialNumber}}
                </div>
              </div>
            </td>
            <td>{{device.commissionDate | date: 'dd.MM.yyyy'}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<p-overlayPanel #appointmentPanel [showCloseIcon]="true">
  <app-ticket-appointment [ticket]="ticket"></app-ticket-appointment>
</p-overlayPanel>
