<!-- Check https://fullcalendar.io/docs/angular for further information -->

<full-calendar #calendar [options]="calendarOptions"></full-calendar>

<p-contextMenu #menu [model]="menuItems" [appendTo]="'body'"></p-contextMenu>

<p-dialog
  #dd
  [(visible)]="showDialog"
  [breakpoints]="{'960px': '75vw', '640px': '90vw'}"
  [style]="{width: '65rem'}"
  [modal]="true"
  [closable]="true"
  [draggable]="false"
  (onHide)="reset()">
  <ng-template pTemplate="header">
    <span class="text-900 font-semibold text-xl dialog-header-text"
      >{{view === 'display' ? appointment.title : view === 'new' ?
      ('calendarComponent.appointment.newAppointment.title' | translate) :
      ('calendarComponent.appointment.editAppointment.title' |
      translate)}}</span
    >
  </ng-template>

  <ng-template pTemplate="content">
    <app-appointment
      [tickets]="tickets"
      [users]="users"
      [(appointment)]="appointment"
      (appointmentChange)="appointmentChanged($event)"
      [view]="view"
      [dialogReference]="dd"></app-appointment>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      pButton
      *ngIf="view === 'edit' && $can('delete')"
      [label]="'general.form.delete' | translate"
      icon="pi pi-trash"
      class="p-button-outlined"
      (click)="showDeleteModal()"></button>

    <button
      pButton
      *ngIf="view === 'display' && $can('update')"
      [label]="'general.form.edit' | translate"
      icon="pi pi-pencil"
      (click)="onEditClick()"></button>
    <button
      pButton
      *ngIf="view === 'new' || view === 'edit' && $can('update')"
      [label]="'general.form.save' | translate"
      icon="pi pi-check"
      (click)="handleSave()"
      [disabled]="handleSaveDisabled()"></button>
  </ng-template>
</p-dialog>

<app-delete-appointment-modal
  [appointment]="this.appointment"
  (notesChanged)="appointmentNotesChanged($event)"
  [display]="showDeleteAppointmentModal"></app-delete-appointment-modal>
