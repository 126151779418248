<p-progressSpinner *ngIf="isLoading"></p-progressSpinner>
<p-table
  *ngIf="!isLoading"
  #formTable
  dataKey="key"
  [rows]="tableConfig.rows"
  [scrollHeight]="tableConfig.scrollHeight"
  [filterDelay]="1"
  [value]="forms"
  [styleClass]="smallTable ? 'p-datatable-sm' : ''"
  [paginator]="true"
  [(selection)]="selectedForms"
  (selectionChange)="onSelectedFormsChange($event)"
  [showCurrentPageReport]="true"
  [currentPageReportTemplate]="'table.pageReportTemplate' | translate"
  [globalFilterFields]="['customerDevice.device.title', 'type', 'createdAt']"
  [rowsPerPageOptions]="tableConfig.rowsPerPageOptions"
  [scrollable]="true">
  <ng-template pTemplate="caption">
    <div class="flex relative align-items-center justify-content-between">
      <div *ngIf="showGlobalSearch">
        <span class="p-input-icon-left ml-auto">
          <i class="pi pi-search"></i>
          <input
            [ngClass]="{'inputtext-sm': smallTable}"
            pInputText
            type="text"
            [value]="globalSearchValue"
            (input)="formTable.filterGlobal($any($event.target).value, 'contains')"
            [placeholder]="'general.globalSearchPlaceholder' | translate" />
        </span>
      </div>
      <div *ngIf="tableConfig.showTableHeader" class="title-container">
        <h4>{{ "formComponent.table.title" | translate}}</h4>
      </div>
      <div>
        <button
          *ngIf="showCreateAction"
          [ngClass]="{'button-sm': smallTable}"
          pButton
          pRipple
          type="button"
          icon="pi pi-plus-circle"
          [label]="'formComponent.newForm' | translate"
          class="flex-auto p-button-outlined"
          (click)="createForm()"></button>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th *ngIf="isSelectable" style="width: 4rem"></th>
      <th pSortableColumn="type" class="white-space-nowrap">
        {{ 'formComponent.table.columns.type.title' | translate }}
        <p-sortIcon field="type" />
      </th>
      <th pSortableColumn="customerDevice" class="white-space-nowrap">
        {{ 'formComponent.table.columns.customerDevice.title' | translate }}
        <p-sortIcon field="customerDevice" />
      </th>
      <th
        *ngIf="showTechnicianColumn"
        pSortableColumn="technician"
        class="white-space-nowrap">
        {{ 'formComponent.table.columns.technician.title' | translate }}
        <p-sortIcon field="technician" />
      </th>
      <th pSortableColumn="deadline" class="white-space-nowrap">
        {{ 'formComponent.table.columns.deadline.title' | translate }}
        <p-sortIcon field="deadline" />
      </th>

      <th
        *ngIf="showCreatedAtColumn"
        pSortableColumn="createdAt"
        class="white-space-nowrap">
        {{ 'formComponent.table.columns.createdAt.title' | translate }}
        <p-sortIcon field="createdAt" />
      </th>
      <th
        *ngIf="showStatusColumn"
        pSortableColumn="status"
        class="white-space-nowrap">
        {{ 'formComponent.table.columns.status.title' | translate }}
        <p-sortIcon field="status" />
      </th>
      <th
        *ngIf="showCompletedAtColumn"
        pSortableColumn="completedAt"
        class="white-space-nowrap">
        {{ 'formComponent.table.columns.completedAt.title' | translate }}
        <p-sortIcon field="completedAt" />
      </th>

      <th *ngIf="showPermanentStatusColumn" class="white-space-nowrap">
        {{ 'formComponent.table.columns.permanentStatusType.title' | translate
        }}
      </th>
      <th
        alignFrozen="right"
        pFrozenColumn
        *ngIf="showDeleteAction || showEditAction || showPdfAction">
        <div class="flex gap-2">
          <div>{{ 'table.actionsTitle' | translate}}</div>
          <div>
            <span
              *ngIf="smallTable && !filterExpanded"
              class="pi pi-filter cursor-pointer"
              (click)="filterExpanded = !filterExpanded"></span>
            <span
              *ngIf="smallTable && filterExpanded"
              class="pi pi-filter-slash cursor-pointer"
              (click)="filterExpanded = !filterExpanded"></span>
          </div>
        </div>
      </th>
    </tr>
    <!-- Filters -->
    <tr *ngIf="(smallTable && filterExpanded) || !smallTable">
      <th *ngIf="isSelectable"></th>
      <th>
        <p-columnFilter field="type" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
              appendTo="body"
              [ngModel]="value"
              [options]="formTypes"
              (onChange)="filter($event.value)">
              <ng-template let-option pTemplate="item">
                <div class="inline-block vertical-align-middle">
                  {{ 'formComponent.attributes.formTypes.' + option |
                  translate}}
                </div>
              </ng-template>
              <ng-template let-options pTemplate="selectedItems">
                <div
                  class="inline-flex align-items-center gap-2 px-1"
                  *ngFor="let option of options; index as i">
                  <p-chip
                    [label]="'formComponent.attributes.formTypes.' + option | translate">
                  </p-chip>
                </div>
                <div *ngIf="!options || options.length === 0">&nbsp;</div>
              </ng-template>
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          field="customerDevice.device.title"
          matchMode="contains"
          [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <input
              type="text"
              pInputText
              [ngModel]="value"
              (ngModelChange)="filter($event)"
              class="p-inputtext" />
          </ng-template>
        </p-columnFilter>
      </th>
      <th *ngIf="showTechnicianColumn"></th>
      <th>
        <p-columnFilter field="deadline" [showMenu]="false" matchMode="date">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-calendar
              [showTime]="false"
              [dateFormat]="'dd.mm.yy'"
              [ngModel]="value"
              (ngModelChange)="filter($event)"
              appendTo="body"
              selectionMode="range"
              [readonlyInput]="true"
              [style]="{ width: '12rem' }" />
          </ng-template>
        </p-columnFilter>
      </th>
      <th *ngIf="showCreatedAtColumn">
        <p-columnFilter
          field="createdAt"
          matchMode="contains"
          [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-calendar
              [firstDayOfWeek]="1"
              appendTo="body"
              [ngModel]="value"
              (ngModelChange)="filter($event)">
            </p-calendar>
          </ng-template>
        </p-columnFilter>
      </th>
      <th *ngIf="showStatusColumn"></th>
      <th *ngIf="showCompletedAtColumn">
        <p-columnFilter field="completedAt" [showMenu]="false" matchMode="date">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-calendar
              [showTime]="false"
              [dateFormat]="'dd.mm.yy'"
              [ngModel]="value"
              (ngModelChange)="filter($event)"
              appendTo="body"
              selectionMode="range"
              [readonlyInput]="true"
              [style]="{ width: '12rem' }" />
          </ng-template>
        </p-columnFilter>
      </th>
      <th *ngIf="showPermanentStatusColumn"></th>
      <th
        alignFrozen="right"
        pFrozenColumn
        *ngIf="showDeleteAction || showEditAction || showPdfAction">
        <div class="flex gap-2">
          <button
            pButton
            pRipple
            [pTooltip]="'general.tooltips.manageFilters' | translate"
            tooltipPosition="left"
            (click)="filterManagement.toggle($event)"
            [outlined]="true"
            [style]="'border-radius: 50%; width: 2rem !important;height: 2rem!important;'"
            icon="pi pi-bars"></button>
          <button
            pButton
            pRipple
            [pTooltip]="'general.tooltips.saveFilters' | translate"
            tooltipPosition="left"
            [style]="'border-radius: 50%; width: 2rem !important;height: 2rem!important;'"
            (click)="filterCreate.toggle($event)"
            [outlined]="true"
            icon="pi pi-save"></button>
          <button
            pButton
            pRipple
            [pTooltip]="'general.tooltips.removeFilter' | translate"
            tooltipPosition="left"
            [style]="'border-radius: 50%; width: 2rem !important;height: 2rem!important;'"
            (click)="clearFilters()"
            [ngClass]="{'p-button-outlined': !isFilterApplied}"
            icon="pi pi-filter-slash"></button>
        </div>
        <p-overlayPanel
          #filterManagement
          (onShow)="displayFilterOverlay = $event">
          <app-filter
            #appFilter
            table="form"
            (filterApplied)="filterApplied($event)"></app-filter>
        </p-overlayPanel>
        <p-overlayPanel #filterCreate>
          <app-filter-create
            (saveFilterClicked)="saveFilterClicked($event);filterCreate.toggle($event)"></app-filter-create>
        </p-overlayPanel>
      </th>
    </tr>
  </ng-template>
  <!-- Body -->
  <ng-template pTemplate="body" let-form>
    <tr [ngClass]="{'deleted-data': form.deletedAt}">
      <td *ngIf="isSelectable">
        <p-tableCheckbox
          *ngIf="!form.completedAt && !form.deletedAt"
          [value]="form"
          [disabled]="selectionDisabled" />
        <i
          *ngIf="form.completedAt && !form.deletedAt"
          class="pi pi-star-fill text-primary"></i>
        <i
          *ngIf="form.deletedAt && !form.completedAt"
          class="pi pi-ban text-primary"></i>
        <i
          *ngIf="form.deletedAt && form.completedAt"
          class="pi pi-star-fill text-primary"></i>
      </td>
      <td>
        {{ 'formComponent.attributes.formTypes.' + form.type | translate}}
      </td>
      <td [ngClass]="{'deleted-data': form.customerDevice?.device?.deletedAt}">
        <div class="flex gap-2 justify-content-between">
          <div>{{ form.customerDevice?.device?.title }}</div>
          <div>
            <p-badge
              value="SN"
              class="serial-number-badge"
              [pTooltip]="
                form.customerDevice.internalDeviceSerialNumber +
        (form.customerDevice.externalDeviceSerialNumber ? '; ' : '') +
        form.customerDevice.externalDeviceSerialNumber
        "></p-badge>
          </div>
        </div>
      </td>
      <td
        *ngIf="showTechnicianColumn"
        [ngClass]="{'deleted-data': form.technician?.deletedAt}">
        <div
          class="inline-block vertical-align-middle flex gap-3 align-items-center white-space-nowrap">
          <ng-container *ngIf="form.technician">
            <app-user-avatar [user]="form.technician" size="normal" />
            <span
              >{{form.technician.firstname}} {{form.technician.lastname}}</span
            >
            <span
              class="pi pi-calendar"
              [pTooltip]="form.showTechnicianCalendar"
              *ngIf="form.showTechnicianCalendar.length > 0 && (form.showTechnicianCalendar || (!form.showTechnicianCalendar && showTechnicianColumn))"></span>
          </ng-container>
          <ng-container *ngIf="!form.technician">
            <div class="w-full flex justify-content-center">-</div>
          </ng-container>
        </div>
      </td>
      <td *ngIf="!deadlineEditable">
        <ng-container *ngIf="form.deadline">
          {{form.deadline | date: 'dd.MM.yyyy HH:mm'}}
        </ng-container>
        <ng-container *ngIf="!form.deadline">
          <div class="w-full flex justify-content-center">-</div>
        </ng-container>
      </td>
      <td
        *ngIf="deadlineEditable"
        [pEditableColumn]="form.deadline"
        pEditableColumnField="deadline">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-calendar
              [firstDayOfWeek]="1"
              appendTo="body"
              dateFormat="dd.mm.yy"
              [iconDisplay]="'input'"
              [showIcon]="true"
              [showTime]="true"
              [required]="true"
              inputId="end"
              [hideOnDateTimeSelect]="false"
              (ngModelChange)="onDeadlineChange($event, form)"
              [(ngModel)]="form.deadline"></p-calendar>
          </ng-template>
          <ng-template pTemplate="output">
            <ng-container *ngIf="form.deadline">
              {{form.deadline | date: 'dd.MM.yyyy HH:mm'}}
            </ng-container>
            <ng-container *ngIf="!form.deadline">-</ng-container>
          </ng-template>
        </p-cellEditor>
      </td>
      <td *ngIf="showCreatedAtColumn">
        <div class="flex gap-3 align-items-center">
          {{form.createdAt | date: 'dd.MM.yyyy HH:mm'}}
        </div>
      </td>
      <td *ngIf="showStatusColumn">
        {{ form.status ?( 'formComponent.statuses.completed' | translate) :
        ('formComponent.statuses.open' | translate)}}
      </td>
      <td *ngIf="showCompletedAtColumn">{{displayDate(form.completedAt)}}</td>
      <td
        *ngIf="showPermanentStatusColumn && ticket && ticket.permanentTicketStatusType">
        <app-ticket-chip
          *ngIf="ticket.permanentTicketStatusType"
          [label]="ticket.permanentTicketStatusType"></app-ticket-chip>
      </td>
      <td
        alignFrozen="right"
        pFrozenColumn
        *ngIf="showDeleteAction || showEditAction || showPdfAction">
        <div class="flex gap-3 align-items-center">
          <!-- The buttons (view and download) are only displayed if the form has already been completed. -->

          <app-table-action
            *ngIf="showPdfAction && form.status"
            (actionClicked)="viewPdf(form)"
            [icon]="'pi pi-eye'"
            [severity]="'success'"
            [pTooltip]="'formComponent.actions.tooltips.view' | translate"></app-table-action>

          <app-table-action
            *ngIf="showPdfAction && form.status"
            (actionClicked)="downloadPdf(form)"
            [icon]="'pi pi-file-pdf'"
            [severity]="'danger'"
            [pTooltip]="'formComponent.actions.tooltips.pdf' | translate"></app-table-action>

          <app-table-action
            *ngIf="showPdfAction && form.status && !fileIsLockedByOtherUser(form) && $can('update')"
            (actionClicked)="editPdf(form)"
            [icon]="'pi pi-file-edit'"
            [severity]="'primary'"
            [pTooltip]="'formComponent.actions.tooltips.editPdf' | translate"></app-table-action>

          <app-table-action
            *ngIf="showPdfAction && form.status && fileIsLockedByOtherUser(form) && hasPermissionToUnlock() && $can('update')"
            (actionClicked)="unlockPdf(form)"
            [icon]="'pi pi-lock-open'"
            [severity]="'primary'"
            [pTooltip]="'formComponent.actions.tooltips.unlock' | translate"></app-table-action>

          <div
            *ngIf="showPdfAction && form.status"
            class="action-divider"></div>

          <app-table-action
            *ngIf="showEditAction && !form.completedAt && !form.deletedAt"
            (actionClicked)="editForm(form)"
            [icon]="'pi pi-pencil'"
            [severity]="'success'"
            [pTooltip]="'formComponent.actions.tooltips.edit' | translate"></app-table-action>

          <app-table-action
            *ngIf="showDeleteAction"
            (actionClicked)="deleteForm(form)"
            [icon]="'pi pi-trash'"
            [severity]="'danger'"
            [pTooltip]="'formComponent.actions.tooltips.delete' | translate"></app-table-action>
        </div>
      </td>
    </tr>
    <tr *ngIf="fileIsLockedByOtherUser(form) && form.file">
      <td colspan="7" class="locked-record">
        <span>
          {{ 'formComponent.formLocked.message' | translate: { lockedDate:
          (form.file.fileLockedAt | date: 'dd.MM.yyyy'), lockedTime:
          (form.file.fileLockedAt | date: 'HH:mm'), userName:
          form.file.fileLockedBy?.firstname + ' ' +
          form.file.fileLockedBy?.lastname } }}
        </span>
      </td>
    </tr>
    <tr *ngIf="fileIsLockedByCurrentUser(form) && form.file">
      <td colspan="7" class="locked-record-current-user">
        <span>
          {{ 'formComponent.formLocked.message' | translate: { lockedDate:
          (form.file.fileLockedAt | date: 'dd.MM.yyyy'), lockedTime:
          (form.file.fileLockedAt | date: 'HH:mm'), userName:
          form.file.fileLockedBy?.firstname + ' ' +
          form.file.fileLockedBy?.lastname } }}
        </span>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-dialog
  [(visible)]="isFileModalVisible"
  [draggable]="false"
  [modal]="true"
  [dismissableMask]="true"
  [closable]="!pdfEditMode"
  [style]="{ width: '92%' }"
  (onHide)="hidePdfViewer()">
  <ng-template pTemplate="headless">
    <ngx-doc-viewer
      *ngIf="fileToView && !pdfEditMode"
      [url]="fileToView"
      viewer="pdf"
      style="height: 80vh" />

    <div *ngIf="fileToView && pdfEditMode">
      <div class="extra-toolbar flex gap-2 justify-content-between">
        <div class="extra-toolbar-left-container"></div>
        <div class="toolbar-caption">{{getFileName()}}</div>
        <div class="extra-toolbar-right-container flex gap-2">
          <i
            class="close-button cursor-pointer pi pi-save"
            [pTooltip]="'formComponent.formLocked.saveEditedDocument' | translate"
            (click)="export()"
            style="font-size: 1.5rem"></i>
          <i
            class="close-button cursor-pointer pi pi-times"
            [pTooltip]="'formComponent.formLocked.cancelEdit' | translate"
            (click)="cancelEdit()"
            style="font-size: 1.5rem"></i>
        </div>
      </div>
      <ngx-extended-pdf-viewer
        #pdfViewer
        *ngIf="fileToView && pdfEditMode"
        [src]="fileToView"
        [height]="'80vh'"
        [showHandToolButton]="true"
        [showOpenFileButton]="true"
        [filenameForDownload]="getFileName()">
      </ngx-extended-pdf-viewer>
    </div>

    <p-progressSpinner *ngIf="!fileToView"></p-progressSpinner>
  </ng-template>
</p-dialog>
