import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MultiSelectChangeEvent } from 'primeng/multiselect';
import { first } from 'rxjs';
import { Country } from 'src/app/models/Country';
import { FileDto } from 'src/app/models/FileDto';
import { FormType, HiveForm } from 'src/app/models/HiveForm';
import { ReoccurringInterval } from 'src/app/models/ReoccurringInterval';
import {
  TicketCategory,
  TicketCreate,
  TicketStatus
} from 'src/app/models/Ticket';
import { User } from 'src/app/models/User';
import { Customer } from 'src/app/models/customer/Customer';
import { CustomerDevice } from 'src/app/models/customer/CustomerDevice';
import { CustomerDeviceService } from 'src/app/services/api/customer/customer-device.service';
import { UserService } from 'src/app/services/api/user.service';
import { generateSubject } from '../utils/createSubject';
import {
  categoryChips,
  clientTypes,
  reoccurring,
  statusChips
} from '../utils/getTicketInfo';
import moment from 'moment';

const DEFAULT_STRING_VALIDATORS = [
  Validators.required,
  Validators.minLength(2)
];

type TicketHiveForm = HiveForm & { uuid: string };
type GroupedHiveForm = {
  customerDevice: CustomerDevice;
  forms: {
    form: TicketHiveForm;
    originalIndex: number;
  }[];
};

@Component({
  selector: 'app-ticket-create-form',
  templateUrl: './ticket-create-form.component.html',
  styleUrls: ['./ticket-create-form.component.scss']
})
export class TicketCreateFormComponent implements OnInit {
  @Input() ticket!: TicketCreate;

  @Input({ required: true }) customers: Customer[] = [];

  @Input({ required: true }) countries: Country[] = [];

  @Input() getCustomersIsLoading = true;

  formGroup: FormGroup | null = null;

  modalVisible = false;

  customerModalVisible = false;

  customerDocumentsModalVisible = false;

  selectedCustomer: Customer | null = null;

  files: File[] = [];

  editors: User[] = [];

  editorsShadow: User[] = [];

  editorFilterValue = '';

  devices: CustomerDevice[] = [];

  devicesShadow: CustomerDevice[] = [];

  customerDeleteAllowed = true;

  hasCustomerDocuments = false;

  selectedFiles: FileDto[] = [];

  customerFiles: FileDto[] = [];

  categoryChips = categoryChips;

  statusChips = statusChips;

  clientTypes = clientTypes;

  reoccurring: string[] = reoccurring;

  showReoccurring = false;

  selectedReoccurring: ReoccurringInterval = ReoccurringInterval.None;

  isReoccurringTicket = true;

  groupedHiveForm: GroupedHiveForm[] = [];

  readonly formTypes;

  constructor(
    private readonly customerDevicesService: CustomerDeviceService,
    private readonly userService: UserService,
    private readonly translate: TranslateService
  ) {
    this.formTypes = Object.values(FormType).map((value) => ({
      label: this.translate.instant(
        `formComponent.attributes.formTypes.${value}`
      ),
      value
    }));
  }

  ngOnInit(): void {
    if (this.ticket.customer) {
      this.onCustomerSelectionChanged(this.ticket.customer);
      this.customerDeleteAllowed = false;
    }

    this.userService
      .findAll()
      .pipe(first())
      .subscribe((users) => {
        this.editors = users;
        this.editorsShadow = users;
      });

    this.formGroup = new FormGroup({
      ticketCategory: new FormControl(this.ticket.ticketCategoryType, {
        validators: DEFAULT_STRING_VALIDATORS
      }),
      customer: new FormControl(this.ticket.customer),
      devices: new FormControl(this.ticket.customerDevices),
      files: new FormControl(this.ticket.files),
      editor: new FormControl(this.ticket.editor, {
        validators: Validators.required
      }),
      subject: new FormControl(this.ticket.subject, {
        validators: DEFAULT_STRING_VALIDATORS
      }),

      forms: new FormArray([]),

      description: new FormControl(this.ticket.description),
      requestReason: new FormControl(this.ticket.requestReason),
      isReoccurringTicket: new FormControl(this.isReoccurringTicket),
      selectedReoccurring: new FormControl(this.selectedReoccurring),
      executionDate: new FormControl(this.ticket.executionDate),
      clientType: new FormControl(this.ticket.clientType, {
        validators: Validators.required
      })
    });

    if (this.formGroup) {
      this.formGroup?.get('ticketCategory')?.valueChanges.subscribe((val) => {
        if (val === TicketCategory.Task) {
          // for clearing validations
          this.formGroup?.get('customer')?.clearValidators();
        } else {
          this.formGroup?.get('customer')?.setValidators(Validators.required);
        }

        this.formGroup?.get('customer')?.updateValueAndValidity();
        this.updateFormsValidators(val);
      });

      this.formGroup
        ?.get('isReoccurringTicket')
        ?.valueChanges.subscribe((isReoccurring) => {
          this.isReoccurringTicket = isReoccurring;
        });

      this.updateFormsValidators(this.formGroup.get('ticketCategory')?.value);
    }

    this.showReoccurring =
      this.ticket.ticketCategoryType === TicketCategory.SGC ||
      this.ticket.ticketCategoryType === TicketCategory.Maintenance;
  }

  get isCustomerRequired(): boolean {
    const control = this.formGroup?.get('customer');

    return control?.hasValidator(Validators.required) ?? false;
  }

  get selectedDevices(): CustomerDevice[] {
    return this.formGroup?.get('devices')?.value ?? [];
  }

  get formArray(): FormArray {
    return this.formGroup!.get('forms') as FormArray;
  }

  public buildTicket(): TicketCreate {
    const executionDate = new Date(this.formGroup?.get('executionDate')?.value);
    let status: TicketStatus = 'Open';
    if (executionDate) {
      const currentDate = new Date();
      const threeMonthsFromNow = new Date();
      threeMonthsFromNow.setMonth(currentDate.getMonth() + 3);

      if (executionDate > threeMonthsFromNow) {
        status = 'Planning';
      }
    }

    return {
      subject: this.formGroup?.get('subject')?.value,
      description: this.formGroup?.get('description')?.value,
      requestReason: this.formGroup?.get('requestReason')?.value,
      files: this.files,
      ticketCategoryType: this.formGroup?.get('ticketCategory')?.value,
      ticketStatusType: status,
      customer: this.selectedCustomer,
      clientType: this.formGroup?.get('clientType')?.value,
      isAppointmentNeeded: true,
      customerDevices: this.formGroup?.get('devices')?.value,
      editor: this.formGroup?.get('editor')?.value,
      customerDocumentIds: this.selectedFiles.map((file) => file.id),
      reoccurringInterval: this.selectedReoccurring,
      executionDate: this.formGroup?.get('executionDate')?.value,
      forms: this.formArray.value.filter((x: TicketHiveForm) => Boolean(x.type))
    };
  }

  onCustomerSelectionChanged(customer: Customer): void {
    this.formGroup?.get('customer')?.setValue(customer);
    this.selectedCustomer = customer;
    this.generateSubject();
    this.hideCustomerModal();

    this.customerDevicesService
      .findAll(customer.id)
      .pipe(first())
      .subscribe((devices) => {
        this.formArray?.clear();
        this.devices = devices.filter((device) => device.isActive);
        this.devicesShadow = devices.filter((device) => device.isActive);

        if (devices.length === 1) {
          this.formGroup?.get('devices')?.setValue([devices[0]]);
          this.addFormItem(devices[0]);
          this.generateSubject();
        }
      });
  }

  chooseCustomerClicked(): void {
    this.modalVisible = true;
    this.customerModalVisible = true;
  }

  removeCustomerClicked(): void {
    this.selectedCustomer = null;
    this.devicesShadow = [];
    this.devices = [];
    this.ticket.customerDevices = [];
    if (this.formGroup) {
      this.formGroup.get('devices')?.setValue([]);
    }
    this.generateSubject();
  }

  /**
   * Hides the customer modal.
   * @returns {void}
   */
  public hideCustomerModal(): void {
    if (!this.hasCustomerDocuments) {
      this.selectedFiles = [];
    }

    this.modalVisible = false;
    this.customerModalVisible = false;
    this.customerDocumentsModalVisible = false;
  }

  filesChosen(files: File[]): void {
    this.files = files;
  }

  resetFunction() {
    this.editors = this.editorsShadow;
    this.editorFilterValue = '';
  }

  editorFilter(event: Event) {
    this.editorFilterValue = (event.target as HTMLInputElement).value;
    this.editors = this.editorsShadow.filter((editor) => {
      const filterValue = this.editorFilterValue.toLowerCase();

      return (
        editor.firstname?.toLowerCase().includes(filterValue) ||
        editor.lastname?.toLowerCase().includes(filterValue)
      );
    });
  }

  showCustomerDocuments(): void {
    this.modalVisible = true;
    this.customerDocumentsModalVisible = true;
  }

  takeCustomerDocuments(): void {
    this.hasCustomerDocuments = true;
    this.hideCustomerModal();
  }

  fileSelectionChanged(files: FileDto[]): void {
    this.selectedFiles = files;
  }

  fileRemoved(fileToRemove: FileDto): void {
    this.selectedFiles = this.selectedFiles.filter(
      (file) => file.id !== fileToRemove.id
    );

    this.selectedCustomer?.files?.forEach((file) => {
      if (file.id === fileToRemove.id) {
        fileToRemove.selected = false;
      }
    });
  }

  /**
   * Generates the subject string for the ticket form based on the selected category, customer, and devices.
   * The subject string is composed of the category translation, customer name, and device details,
   * separated by slashes. Only non-empty values are included in the subject string.
   * @returns {void}
   */
  generateSubject(): void {
    // Get the selected category from the form group
    const category = this.formGroup?.get('ticketCategory')?.value ?? null;

    this.showReoccurring =
      category === TicketCategory.SGC ||
      category === TicketCategory.Maintenance;

    // Translate the category if it exists
    const categoryTranslation = category
      ? this.translate.instant(`ticketComponent.ticketChips.${category}`)
      : '';

    // Get the selected customer's name
    const customer = this.selectedCustomer?.name ?? '';

    // Get the list of devices from the form group
    const devices = this.formGroup?.get('devices')?.value as CustomerDevice[];

    // Generate the subject string using the helper function
    const subject = generateSubject(categoryTranslation, customer, devices);

    // If the form group exists, set the subject field with the generated subject string
    if (this.formGroup) {
      this.formGroup.get('subject')?.setValue(subject);
    }
  }

  changeDevices(event: MultiSelectChangeEvent): void {
    const values = event.value as CustomerDevice[];

    const currentFormsValue: TicketHiveForm[] =
      this.formGroup?.get('forms')?.value ?? [];

    // remove unselected devices from the form array
    [...currentFormsValue].forEach((form) => {
      const exists = values.some(
        (device) => device.id === form.customerDevice?.id
      );

      if (!exists) {
        this.removeFormItem(form.uuid, false);
      }
    });

    // add new form for new devices
    values.forEach((customerDevice) => {
      const exists = currentFormsValue.some(
        (form) => form.customerDevice?.id === customerDevice.id
      );

      if (!exists) {
        this.addFormItem(customerDevice, false);
      }
    });

    this.generateGroupedHiveForm();

    // regenerate the subject
    this.generateSubject();
  }

  onIsReoccurringTicketChange(): void {
    this.ticket.reoccurringInterval = this.selectedReoccurring;
    this.selectedReoccurring = this.formGroup?.get(
      'selectedReoccurring'
    )?.value;
  }

  onExecutionDateChanged(executionDate: Date): void {
    this.ticket.executionDate = executionDate;
  }

  deviceHasSerialNumber(device: CustomerDevice): boolean {
    return (
      device.internalDeviceSerialNumber !== null ||
      device.externalDeviceSerialNumber !== null
    );
  }

  getSerialNumberLabel(device: CustomerDevice, fillChar = ';'): string {
    const { internalDeviceSerialNumber, externalDeviceSerialNumber } = device;
    let retVal = '';

    if (internalDeviceSerialNumber && externalDeviceSerialNumber) {
      retVal = `${internalDeviceSerialNumber}${fillChar} ${externalDeviceSerialNumber}`;
    }
    if (!internalDeviceSerialNumber && externalDeviceSerialNumber) {
      retVal = `${externalDeviceSerialNumber}`;
    }
    if (internalDeviceSerialNumber && !externalDeviceSerialNumber) {
      retVal = `${internalDeviceSerialNumber}`;
    }

    return retVal;
  }

  formatComissionDate(device: CustomerDevice): string {
    return device.commissionDate
      ? moment(device.commissionDate).format('DD.MM.YYYY')
      : '';
  }

  createFormItem(customerDevice: CustomerDevice): FormGroup {
    return new FormGroup({
      uuid: new FormControl(Math.random().toString(36), Validators.required),
      type: new FormControl(undefined, Validators.required),
      customerDevice: new FormControl(customerDevice, Validators.required)
    });
  }

  // Add a new form item to the 'forms' FormArray
  addFormItem(customerDevice: CustomerDevice, generate = true): void {
    this.formArray.push(this.createFormItem(customerDevice));

    if (generate) {
      this.generateGroupedHiveForm();
    }

    this.updateFormsValidators(this.formGroup?.get('ticketCategory')?.value);
  }

  removeFormItem(uuid: string, generate = true): void {
    const index = this.formArray.controls.findIndex(
      (control) => control.get('uuid')?.value === uuid
    );

    this.formArray.removeAt(index);

    if (generate) {
      this.generateGroupedHiveForm();
    }

    this.updateFormsValidators(this.formGroup?.get('ticketCategory')?.value);
  }

  getFormsByCustomerDevice(customerDevice: CustomerDevice): TicketHiveForm[] {
    return this.formArray.value.filter(
      (form: TicketHiveForm) => form.customerDevice!.id === customerDevice.id
    );
  }

  generateGroupedHiveForm(): void {
    const result: GroupedHiveForm[] = this.formArray.value.reduce(
      (
        arr: GroupedHiveForm[],
        hiveForm: TicketHiveForm,
        hiveFormIndex: number
      ) => {
        const index = arr.findIndex(
          (group) => group.customerDevice.id === hiveForm.customerDevice?.id
        );

        if (index !== -1) {
          arr[index].forms.push({
            form: hiveForm,
            originalIndex: hiveFormIndex
          });

          return arr;
        }

        arr.push({
          customerDevice: hiveForm.customerDevice!,
          forms: [
            {
              form: hiveForm,
              originalIndex: hiveFormIndex
            }
          ]
        });

        return arr;
      },
      [] as GroupedHiveForm[]
    );

    result.sort((a, b) =>
      a.customerDevice.device!.title.localeCompare(
        b.customerDevice.device!.title
      )
    );

    this.groupedHiveForm = result;
  }

  updateFormsValidators(ticketCategory: TicketCategory | undefined): void {
    const formsArray = this.formGroup?.get('forms') as FormArray | undefined;

    formsArray?.controls.forEach((formGroup) => {
      // Loop through all form controls in the FormArray
      const group = formGroup as FormGroup;

      Object.keys(group.controls).forEach((controlName) => {
        const control = formGroup.get(controlName);

        if (ticketCategory === TicketCategory.Task) {
          // If category is 'Task', remove validators
          control?.clearValidators();
        } else {
          // If category is not 'Task', apply required validators
          control?.setValidators(Validators.required);
        }

        // Update the control's validation status
        control?.updateValueAndValidity();
      });
    });
  }
}
