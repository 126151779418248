// Angular specifics
import { ClipboardModule } from '@angular/cdk/clipboard';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
import { NgxEchartsModule } from 'ngx-echarts';
import { SelectButtonModule } from 'primeng/selectbutton';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutModule } from './layout/app.layout.module';

// PrimeNG Services
import { ConfirmationService, MessageService } from 'primeng/api';

// PrimeNG component modules
import { GridsterModule } from 'angular-gridster2';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

// Custom components
import { ProgressBarModule } from 'primeng/progressbar';
import { FilterCreateComponent } from 'src/app/components/misc/generic-table/filter/filter-create/filter-create.component';
import { FilterComponent } from 'src/app/components/misc/generic-table/filter/filter.component';
import { CurrencyComponent } from './components/admin/currency/currency.component';
import { CustomerAvatarComponent } from './components/admin/customer/components/customer-avatar/customer-avatar.component';
import { CustomerCreateFormComponent } from './components/admin/customer/components/customer-create-form/customer-create-form.component';
import { CustomerTableComponent } from './components/admin/customer/components/customer-table/customer-table.component';
import { DisplayAddressLazyComponent } from './components/admin/customer/components/display-address-lazy/display-address-lazy.component';
import { CustomerComponent } from './components/admin/customer/customer.component';
import { CustomerEditBaseDataComponent } from './components/admin/customer/views/components/customer-edit-base-data/customer-edit-base-data.component';
import { CustomerEditContactPersonComponent } from './components/admin/customer/views/components/customer-edit-contact-person/customer-edit-contact-person.component';
import { CustomerEditCorrespondenceComponent } from './components/admin/customer/views/components/customer-edit-correspondence/customer-edit-correspondence.component';
import { CustomerEditDevicesComponent } from './components/admin/customer/views/components/customer-edit-devices/customer-edit-devices.component';
import { CustomerEditDocumentsComponent } from './components/admin/customer/views/components/customer-edit-documents/customer-edit-documents.component';
import { CustomerEditTicketsComponent } from './components/admin/customer/views/components/customer-edit-tickets/customer-edit-tickets.component';
import { CustomerEditComponent } from './components/admin/customer/views/customer-edit.component';
import { DeviceComponent } from './components/admin/device/device.component';
import { HeatingEngineerComponent } from './components/admin/heating-engineer/heating-engineer.component';
import { TicketTableComponent } from './components/admin/ticket/components/ticket-table/ticket-table.component';
import { TicketComponent } from './components/admin/ticket/ticket.component';
import { UserAvatarComponent } from './components/admin/users/user-avatar/user-avatar.component';
import { UsersComponent } from './components/admin/users/users.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { LoggedOutLayoutComponent } from './components/auth/logged-out-layout/logged-out-layout.component';
import { LoginComponent } from './components/auth/login/login.component';
import { NewPasswordComponent } from './components/auth/newpassword/newpassword.component';
import { ProfileImageComponent } from './components/auth/profile/profile-image/profile-image.component';
import { ProfileComponent } from './components/auth/profile/profile.component';
import { VerificationComponent } from './components/auth/verification/verification.component';
import { FileUploadComponent } from './components/misc/file-upload/file-upload.component';
import { GenericColumnBodyComponent } from './components/misc/generic-table/generic-column-body/generic-column-body.component';
import { GenericColumnHeaderComponent } from './components/misc/generic-table/generic-column-header/generic-column-header.component';
import { GenericTableComponent } from './components/misc/generic-table/generic-table.component';
import { LanguageModule } from './components/misc/language-switcher/language-switcher.module';
import { LoggingComponent } from './components/misc/logging/logging.component';
import { PasswordGuidelinesComponent } from './components/misc/password-guidelines/password-guidelines.component';
import { SearchHighlightComponent } from './components/misc/search-highlight/search-highlight.component';
import { CrudComponent } from './components/templates/crud/crud.component';
import { FormComponent } from './components/templates/crud/form/form.component';
import { SidebarComponent } from './components/templates/crud/sidebar/sidebar.component';
import { HomeComponent } from './components/templates/home/home.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';

import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { FilesComponent } from './components/misc/file-upload/files/files.component';
import { MsgViewerComponent } from './components/misc/file-upload/files/msg-viewer/msg-viewer.component';

import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ability, PureAbility } from '@casl/ability';
import { AbilityModule } from '@casl/angular';
import { FullCalendarModule } from '@fullcalendar/angular';
import { RippleModule } from 'primeng/ripple';
import { CalendarOverviewComponent } from './components/admin/calendar-overview/calendar-overview.component';
import { AppointmentComponent } from './components/admin/calendar-overview/calendar/appointment/appointment.component';
import { CalendarComponent } from './components/admin/calendar-overview/calendar/calendar.component';
import { CustomerStatusBadgeComponent } from './components/admin/customer/components/customer-status-badge/customer-status-badge.component';
import { CustomerTableActionNewCorrespondenceComponent } from './components/admin/customer/components/customer-table-action-new-correspondence/customer-table-action-new-correspondence.component';
import { CustomerTableActionNewTicketComponent } from './components/admin/customer/components/customer-table-action-new-ticket/customer-table-action-new-ticket.component';
import { CustomerContactPersonFormComponent } from './components/admin/customer/views/components/customer-edit-contact-person/components/customer-contact-person-form/customer-contact-person-form.component';
import { CustomerContactPersonTableComponent } from './components/admin/customer/views/components/customer-edit-contact-person/components/customer-contact-person-table/customer-contact-person-table.component';
import { CustomerDeviceFormComponent } from './components/admin/customer/views/components/customer-edit-devices/components/customer-device-form/customer-device-form.component';
import { CustomerDeviceTableComponent } from './components/admin/customer/views/components/customer-edit-devices/components/customer-device-table/customer-device-table.component';
import { TicketChipComponent } from './components/admin/ticket/components/ticket-chip/ticket-chip.component';
import { TicketCreateFormComponent } from './components/admin/ticket/components/ticket-create-form/ticket-create-form.component';
import { TicketAppointmentComponent } from './components/admin/ticket/components/ticket-edit/ticket-appointment/ticket-appointment.component';
import { TicketAttachmentsComponent } from './components/admin/ticket/components/ticket-edit/ticket-attachments/ticket-attachments.component';
import { TicketContactPersonComponent } from './components/admin/ticket/components/ticket-edit/ticket-contact-person/ticket-contact-person.component';
import { TicketCustomerComponent } from './components/admin/ticket/components/ticket-edit/ticket-customer/ticket-customer.component';
import { TicketDetailsComponent } from './components/admin/ticket/components/ticket-edit/ticket-details/ticket-details.component';
import { TicketDevicesComponent } from './components/admin/ticket/components/ticket-edit/ticket-devices/ticket-devices.component';
import { TicketEditComponent } from './components/admin/ticket/components/ticket-edit/ticket-edit.component';
import { TicketFormComponent } from './components/admin/ticket/components/ticket-edit/ticket-form/ticket-form.component';
import { TicketHeaderComponent } from './components/admin/ticket/components/ticket-edit/ticket-header/ticket-header.component';
import { TicketInformationComponent } from './components/admin/ticket/components/ticket-edit/ticket-information/ticket-information.component';
import { TicketSummaryComponent } from './components/admin/ticket/components/ticket-summary/ticket-summary.component';
import { MsgViewerModalComponent } from './components/misc/file-upload/files/msg-viewer/msg-viewer-modal/msg-viewer-modal.component';
import { AutocompleteComponent } from './components/templates/crud/form/form-fields/autocomplete/autocomplete.component';
import { CreatedAtFilterPipe } from './utils/pipes/created-at-filter.pipe';
import { CreatedByFilterPipe } from './utils/pipes/created-by-filter.pipe';
import { FileFilterPipe } from './utils/pipes/file-filter.pipe';
import { FileNameFilterPipe } from './utils/pipes/file-name-filter.pipe';
import { FileSizePipe } from './utils/pipes/file-size.pipe';
import { FormatTextPipe } from './utils/pipes/format-text.pipe';
import { SafePipe } from './utils/pipes/safe.pipe';
import { ExpandableTextComponent } from './components/misc/expandable-text/expandable-text.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';
import { AppointmentMyDayComponent } from 'src/app/components/admin/my-day-overview/day-card/appointment-my-day/appointment-my-day.component';
import { MyDayOverviewComponent } from 'src/app/components/admin/my-day-overview/my-day-overview.component';
import { CutTextComponent } from 'src/app/components/misc/cut-text/cut-text.component';
import { ErrorViewComponent } from 'src/app/components/misc/error-view/error-view.component';
import { DashboardComponent } from './components/admin/dashboard/dashboard.component';
import { WidgetChooserComponent } from './components/admin/dashboard/widget-chooser/widget-chooser.component';
import { WidgetWrapperComponent } from './components/admin/dashboard/widget-chooser/widget-wrapper/widget-wrapper.component';
import { WidgetEmptyComponent } from './components/admin/dashboard/widgets/widget-empty/widget-empty.component';
import { WidgetTicketCommissioningComponent } from './components/admin/dashboard/widgets/widget-ticket-commissioning/widget-ticket-commissioning.component';
import { WidgetTicketEmergencyComponent } from './components/admin/dashboard/widgets/widget-ticket-emergency/widget-ticket-emergency.component';
import { WidgetTicketMaintenanceComponent } from './components/admin/dashboard/widgets/widget-ticket-maintenance/widget-ticket-maintenance.component';
import { WidgetTicketMalfunctionComponent } from './components/admin/dashboard/widgets/widget-ticket-malfunction/widget-ticket-malfunction.component';
import { WidgetTicketTaskComponent } from './components/admin/dashboard/widgets/widget-ticket-task/widget-ticket-task.component';
import { WidgetTicketsAllComponent } from './components/admin/dashboard/widgets/widget-tickets-all/widget-tickets-all.component';
import { WidgetTicketSgcComponent } from './components/admin/dashboard/widgets/widget-ticket-sgc/widget-ticket-sgc.component';
import { ChangeAppointmentStatusComponent } from './components/admin/my-day-overview/day-card/appointment-my-day/change-appointment-status/change-appointment-status.component';
import { DayCardComponent } from './components/admin/my-day-overview/day-card/day-card.component';
import { FormTableComponent } from './components/admin/ticket/components/ticket-edit/ticket-form/form-table/form-table.component';
import { TicketReoccurringModalComponent } from './components/admin/ticket/components/ticket-edit/ticket-reoccurring-modal/ticket-reoccurring-modal.component';
import { UserProfileViewComponent } from './components/admin/users/user-avatar/user-profile-view/user-profile-view.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { LoadingComponent } from './components/misc/loading/loading.component';
import { TableActionComponent } from './components/misc/table-action/table-action.component';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DeleteAppointmentModalComponent } from './components/admin/calendar-overview/calendar/delete-appointment-modal/delete-appointment-modal.component';

// AoT requires an exported function for factories
// eslint-disable-next-line require-jsdoc
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    CrudComponent,
    CurrencyComponent,
    ForgotPasswordComponent,
    FormComponent,
    GenericColumnBodyComponent,
    GenericColumnHeaderComponent,
    GenericTableComponent,
    HomeComponent,
    LoggedOutLayoutComponent,
    LoginComponent,
    NewPasswordComponent,
    PasswordGuidelinesComponent,
    ProfileComponent,
    ProfileImageComponent,
    LoggingComponent,
    SearchHighlightComponent,
    SidebarComponent,
    UsersComponent,
    VerificationComponent,
    FilterComponent,
    FilterCreateComponent,
    UserAvatarComponent,
    HeatingEngineerComponent,
    CustomerComponent,
    CustomerTableComponent,
    CustomerCreateFormComponent,
    CustomerEditComponent,
    CustomerEditBaseDataComponent,
    CustomerEditContactPersonComponent,
    CustomerEditDevicesComponent,
    CustomerEditTicketsComponent,
    CustomerEditCorrespondenceComponent,
    CustomerEditDocumentsComponent,
    CustomerAvatarComponent,
    DisplayAddressLazyComponent,
    DeviceComponent,
    FileUploadComponent,
    FileSizePipe,
    FilesComponent,
    MsgViewerComponent,
    FormatTextPipe,
    SafePipe,
    FileFilterPipe,
    CreatedAtFilterPipe,
    CreatedByFilterPipe,
    FileNameFilterPipe,
    CustomerTableActionNewCorrespondenceComponent,
    CustomerTableActionNewTicketComponent,
    CustomerDeviceTableComponent,
    CustomerDeviceFormComponent,
    CustomerStatusBadgeComponent,
    MsgViewerModalComponent,
    TicketComponent,
    TicketTableComponent,
    TicketChipComponent,
    TicketCreateFormComponent,
    CustomerContactPersonTableComponent,
    CustomerContactPersonFormComponent,
    TicketEditComponent,
    TicketDetailsComponent,
    TicketInformationComponent,
    TicketCustomerComponent,
    TicketAttachmentsComponent,
    TicketDevicesComponent,
    TicketAppointmentComponent,
    TicketFormComponent,
    TicketHeaderComponent,
    TicketContactPersonComponent,
    CalendarOverviewComponent,
    CalendarComponent,
    AppointmentComponent,
    TicketSummaryComponent,
    MyDayOverviewComponent,
    DayCardComponent,
    AppointmentMyDayComponent,
    AutocompleteComponent,
    TicketSummaryComponent,
    DashboardComponent,
    CutTextComponent,
    LoadingComponent,
    TableActionComponent,
    UserProfileViewComponent,
    ChangeAppointmentStatusComponent,
    DeleteAppointmentModalComponent,
    ExpandableTextComponent,

    // Widgets
    WidgetTicketEmergencyComponent,
    WidgetTicketMalfunctionComponent,
    WidgetTicketMaintenanceComponent,
    WidgetTicketCommissioningComponent,
    WidgetTicketTaskComponent,
    WidgetTicketsAllComponent,
    WidgetTicketSgcComponent,
    WidgetChooserComponent,
    WidgetWrapperComponent,
    WidgetEmptyComponent,
    FormTableComponent,
    TicketReoccurringModalComponent,

    // Error View
    ErrorViewComponent
  ],
  imports: [
    BadgeModule,
    AppLayoutModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    ClipboardModule,
    ChipModule,
    ConfirmDialogModule,
    DialogModule,
    DividerModule,
    DropdownModule,
    DynamicDialogModule,
    FileUploadModule,
    FormsModule,
    InputNumberModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    LanguageModule,
    MultiSelectModule,
    OverlayPanelModule,
    PasswordModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    SelectButtonModule,
    SidebarModule,
    TableModule,
    ToastModule,
    AvatarModule,
    TooltipModule,
    TabViewModule,
    TagModule,
    ProgressBarModule,
    ImageModule,
    NgxDocViewerModule,
    AbilityModule,
    FullCalendarModule,
    CommonModule,
    FormsModule,
    FullCalendarModule,
    DialogModule,
    InputTextareaModule,
    ButtonModule,
    CalendarModule,
    RippleModule,
    DragDropModule,
    AutoCompleteModule,
    GridsterModule,
    ChartModule,
    ColorPickerModule,
    FieldsetModule,
    AccordionModule,
    MenuModule,
    NgxExtendedPdfViewerModule,
    ContextMenuModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    ConfirmationService,
    DialogService,
    MessageService,
    TranslateService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    { provide: Ability, useValue: new Ability() },
    { provide: PureAbility, useExisting: Ability },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler()
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
