import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Dialog } from 'primeng/dialog';
import { Appointment } from 'src/app/models/Appointment';
import { Ticket } from 'src/app/models/Ticket';
import { User } from 'src/app/models/User';
import { Router } from '@angular/router';
import { DropdownFilterEvent } from 'primeng/dropdown';
import { HiveForm } from 'src/app/models/HiveForm';
import { TranslateService } from '@ngx-translate/core';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { CalendarDate } from 'src/app/utils/interfaces/calendar-date.interface';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrl: './appointment.component.scss'
})
export class AppointmentComponent implements OnInit {
  @Input({ required: true }) appointment!: Appointment;

  @Input() dialogReference!: Dialog;

  @Input() tickets: Ticket[] = [];

  @Input() view = '';

  private _users: User[] = [];

  isLoading = false;

  @Input()
  set users(users: User[]) {
    if (users) {
      this._users = users.map((user) => ({
        ...user,
        optionLabel: `${user.firstname} ${user.lastname}`
      }));
    }
  }

  get users(): User[] {
    return this._users;
  }

  @Output() appointmentChange = new EventEmitter<Appointment>();

  activeIndex: number | undefined = 1;

  appointmentShadow!: Appointment;

  duration: string = '';

  selectedForms: HiveForm[] = [];

  selectedOtherTechnicians: User[] = [];

  ticketFilterValue = '';

  ticketsShadow: Ticket[] = [];

  start: CalendarDate = {
    date: new Date(),
    time: new Date()
  };

  end: CalendarDate = {
    date: new Date(),
    time: new Date()
  };

  constructor(
    private readonly router: Router,
    private readonly messageCenterService: MessageCenterService,
    private readonly translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.ticketsShadow = [...this.tickets];
    this.appointmentShadow = { ...this.appointment };

    if (this.appointment.id) {
      this.selectedForms = this.appointment.forms
        ? [...this.appointment.forms]
        : [];
      if (this.appointment.otherTechnicians) {
        this.selectedOtherTechnicians = this.appointment.otherTechnicians;
      }
    }

    if (!this.appointment.id && this.appointment.ticket) {
      this.appointment.forms = this.appointment.ticket.forms
        ? [...this.appointment.ticket.forms]
        : [];
      this.selectedForms = [...this.appointment.forms];
    }

    if (!this.appointment.id) {
      this.appointment.allDay = false;
    }

    if (this.appointment.start) {
      this.start.date = new Date(this.appointment.start);
      this.start.time = new Date(this.appointment.start);
    }

    if (this.appointment.end) {
      this.end.date = new Date(this.appointment.end);
      this.end.time = new Date(this.appointment.end);
    }

    this.calculateDuration();
    this.isLoading = false;

    if (!this.appointment.id && this.appointment.technician) {
      this.technicianSelected(this.appointment.technician);
    }
  }

  /**
   * Emits an event when the all-day status of the appointment changes.
   *
   * @returns {void}
   */
  allDayChanged(): void {
    if (this.appointment.allDay) {
      const startDate = new Date(this.start.date);
      const endDate = new Date(this.end.date);

      // Set start time to 00:00
      startDate.setHours(0, 0, 0, 0);
      this.appointment.start = startDate;
      this.start.date = startDate;
      this.start.time = startDate;

      // Set end time to 23:59
      endDate.setHours(0, 0, 0, 0);
      this.appointment.end = endDate;
      this.end.date = endDate;
      this.end.time = endDate;
    }

    this.appointmentChange.emit(this.appointment);
  }

  /**
   * Navigates to the ticket details page for the given ticket ID.
   *
   * @param {number} ticketId - The ID of the ticket to navigate to.
   * @returns {void}
   */
  navigateToTicket(ticketId: number): void {
    this.router.navigate(['/tickets', ticketId]);
  }

  /**
   * Navigates to the customer details page for the given customer ID.
   *
   * @param {number} customerId - The ID of the customer to navigate to.
   * @returns {void}
   */
  navigateToCustomer(customerId: number): void {
    this.router.navigate(['/customers', customerId]);
  }

  /**
   * Maps the selected users to the appointment's other technicians and their IDs.
   *
   * @param {User[]} selectedUsers - The list of selected users.
   * @returns {void}
   */
  mapOtherTechnicians(selectedUsers: User[]): void {
    this.appointment.otherTechnicians = selectedUsers;
    this.appointment.otherTechniciansIds = selectedUsers
      .filter((user) => user.id !== undefined)
      .map((user) => user.id!);
    this.appointmentChange.emit(this.appointment);
  }

  /**
   * Custom filter function for filtering tickets by ticket number or subject.
   *
   * @param {DropdownFilterEvent} event - The filter event containing the query.
   * @returns {void}
   */
  customFilter(event: DropdownFilterEvent): void {
    const query = event.filter.toLowerCase();
    this.tickets = this.tickets.filter(
      (ticket) =>
        ticket.ticketNumber.toLowerCase().includes(query) ||
        ticket.subject.toLowerCase().includes(query)
    );
  }

  /**
   * Resets the ticket list to its original state and clears the filter value.
   *
   * @returns {void}
   */
  resetFunction(): void {
    this.tickets = this.ticketsShadow;
    this.ticketFilterValue = '';
  }

  /**
   * Filters the ticket list based on the input event's value.
   *
   * @param {Event} event - The input event containing the filter value.
   * @returns {void}
   */
  ticketFilter(event: Event): void {
    this.ticketFilterValue = (event.target as HTMLInputElement).value;
    this.tickets = this.ticketsShadow.filter((ticket) => {
      const filterValue = this.ticketFilterValue.toLowerCase();

      return (
        ticket.ticketNumber.toLowerCase().includes(filterValue) ||
        ticket.subject.toLowerCase().includes(filterValue)
      );
    });
  }

  /**
   * Updates the active index based on the provided index value.
   *
   * @param {number | number[]} index - The new index value. Can be a single number or an array of numbers.
   * If an array is provided, the first element of the array will be used as the new index.
   *
   * @returns {void}
   */
  activeIndexChange(index: number | number[]): void {
    if (Array.isArray(index)) {
      [this.activeIndex] = index;
    } else {
      this.activeIndex = index;
    }
  }

  notesChanged(notes: string): void {
    this.appointment.notes = notes;
    this.appointmentChange.emit(this.appointment);
  }

  /**
   * Calculates the number of missing information fields (title and description) in the appointment.
   *
   * @returns {string} The count of missing information fields as a string.
   */
  getInformationToDos(): string {
    let count = 0;
    if (!this.appointment.title) {
      count++;
    }
    if (!this.appointment.description) {
      count++;
    }

    return `${count}`;
  }

  /**
   * Calculates the number of missing required fields (start, end, and technician) in the appointment.
   *
   * @returns {string} The count of missing required fields as a string.
   */
  getAppointmentToDos(): string {
    let count = 0;
    if (!this.appointment.start) {
      count++;
    }
    if (!this.appointment.end) {
      count++;
    }
    if (!this.appointment.technician) {
      count++;
    }

    return `${count}`;
  }

  /**
   * Calculates the number of missing required fields related to the ticket in the appointment.
   *
   * If a ticket is selected and no forms are selected, the count should be 1.
   *
   * @returns {string} The count of missing required fields related to the ticket as a string.
   */
  getTicketToDos(): string {
    let count = 0;
    if (this.appointment.ticket && this.selectedForms.length === 0) {
      count++;
    }

    return `${count}`;
  }

  /**
   * Handles the selection of a ticket.
   *
   * @param {Ticket} ticket - The selected ticket.
   * @returns {void}
   */
  ticketSelected(ticket: Ticket): void {
    this.appointment.ticket = ticket;
    this.appointment.description = ticket.description;
    this.appointment.title = ticket.subject;
    this.appointment.forms = ticket.forms || [];

    const technicianForms = this.appointment.forms.filter(
      (form) => form.technician?.id === this.appointment.technician?.id
    );

    if (technicianForms.length > 0) {
      this.selectedForms = this.appointment.forms.filter(
        (form) =>
          !form.technicianId ||
          form.technicianId === this.appointment.technician?.id
      );
    } else {
      this.selectedForms = [];
    }

    this.appointmentChange.emit(this.appointment);
  }

  /**
   * Handles the selection of a technician and updates the appointment and related forms.
   *
   * @param {User} technician - The selected technician.
   * @returns {void}
   */
  technicianSelected(technician: User): void {
    this.isLoading = true;
    this.appointment.technician = technician;

    // Update technician for forms in the appointment
    if (this.appointment.forms) {
      this.updateTechnicianForForms(this.appointment.forms, technician);
    }

    // Update technician for forms in the ticket
    if (this.appointment.ticket?.forms) {
      this.updateTechnicianForForms(this.appointment.ticket.forms, technician);
    }

    this.appointmentChange.emit(this.appointment);
    setTimeout(() => {
      this.isLoading = false;
    }, 50);
  }

  /**
   * Updates the technician for the given forms if they meet certain conditions.
   *
   * @param {Form[]} forms - The forms to update.
   * @param {User} technician - The technician to set.
   * @returns {void}
   */
  private updateTechnicianForForms(forms: HiveForm[], technician: User): void {
    if (forms) {
      forms.forEach((form) => {
        if (
          !form.installationIdmLuftFormId &&
          !form.installationIdmSwFormId &&
          !form.sgcMaintenanceIdmFormId &&
          !form.serviceReportFormId &&
          !form.deletedAt
        ) {
          form.technician = technician;
        }
      });
    }
  }

  /**
   * Handles changes to the selected forms for the appointment.
   *
   * This method is called when the selected forms are changed. It updates the selected forms
   * and the forms associated with the appointment, then emits the updated appointment.
   *
   * @param {HiveForm[]} selectedForms - The array of selected forms.
   */
  async handleSelectedFormsChange(selectedForms: HiveForm[]): Promise<void> {
    const now = new Date();
    const appointmentStart = new Date(this.appointment.start);

    if (appointmentStart <= now && this.view === 'edit') {
      const edit = await new Promise<boolean>((resolve) => {
        this.messageCenterService.confirm(
          this.translate.instant(
            'calendarComponent.appointment.editAppointment.editWarning.title'
          ),
          this.translate.instant(
            'calendarComponent.appointment.editAppointment.editWarning.summary'
          ),
          () => {
            resolve(true);
          },
          () => {
            resolve(false);
          }
        );
      });
      if (!edit) {
        this.selectedForms = [...this.selectedForms];

        return;
      }
    }
    const newForm = selectedForms.find(
      (form) =>
        !this.selectedForms.some((existingForm) => existingForm.id === form.id)
    );
    let saveChanges = true;
    if (
      newForm &&
      newForm.technician &&
      newForm.technician.id !== this.appointment.technician?.id
    ) {
      saveChanges = await new Promise<boolean>((resolve) => {
        this.messageCenterService.confirm(
          this.translate.instant(
            'calendarComponent.appointment.formWarning.header'
          ),
          this.translate.instant(
            'calendarComponent.appointment.formWarning.message'
          ),
          () => {
            resolve(true);
          },
          () => {
            resolve(false);
          }
        );
      });
    }

    if (saveChanges) {
      this.selectedForms = selectedForms;
      const index = this.selectedForms.findIndex(
        (form) => form.id === newForm?.id
      );
      if (index > -1) {
        this.selectedForms[index].technician = this.appointment.technician;
        if (this.appointment.technician) {
          this.selectedForms[index].technicianId =
            this.appointment.technician.id;
        }
      }
    } else {
      this.selectedForms = [...this.selectedForms];
    }
    this.appointment.forms = this.selectedForms;
    this.appointmentChange.emit(this.appointment);
  }

  /**
   * Handles the event when a form is edited. Updates the form in the appointment's forms array
   * if it already exists, or adds it to the array if it does not. Emits the updated appointment.
   *
   * @param {HiveForm} form - The edited form to be handled.
   */
  handleFormEdited(form: HiveForm): void {
    if (this.appointment.forms) {
      const index = this.appointment.forms.findIndex(
        (selectedForm) => selectedForm.id === form.id
      );
      if (index > -1) {
        // Update the existing form in the array
        this.appointment.forms[index] = form;
      } else {
        // Add the new form to the array
        this.appointment.forms.push(form);
      }
      // Emit the updated appointment
      this.appointmentChange.emit(this.appointment);
    }
  }

  /**
   * Updates the start and end dates of the appointment based on the selected start and end dates and times.
   * Manually triggers change detection and calculates the duration of the appointment.
   *
   * @returns {void}
   */
  onDateChange(): void {
    if (this.start.date && this.start.time) {
      this.appointment.start = new Date(
        this.start.date.getFullYear(),
        this.start.date.getMonth(),
        this.start.date.getDate(),
        this.start.time.getHours(),
        this.start.time.getMinutes()
      );
    }

    if (this.end.date && this.end.time) {
      this.appointment.end = new Date(
        this.end.date.getFullYear(),
        this.end.date.getMonth(),
        this.end.date.getDate(),
        this.end.time.getHours(),
        this.end.time.getMinutes()
      );
    }
    // Manually trigger change detection
    this.cdr.detectChanges();

    // Calculate duration after change detection
    this.calculateDuration();
  }

  /**
   * Handles changes to the "all day" setting of the appointment.
   * Uses a timeout to ensure the changes are applied before recalculating the duration.
   *
   * @returns {void}
   */
  onAllDayChange(): void {
    setTimeout(() => {
      this.allDayChanged();
      this.calculateDuration();
    });
  }

  onReservedChange(): void {
    this.appointment.reserved = !this.appointment.reserved;
    const reservedText = this.translate.instant('general.form.reserved');
    if (this.appointment.reserved) {
      if (!this.appointment.title.startsWith(reservedText)) {
        this.appointment.title = `${reservedText}${this.appointment.title}`;
      }
    } else {
      this.appointment.title = this.appointment.title.replace(reservedText, '');
    }
    this.appointmentChange.emit(this.appointment);
  }

  /**
   * Calculates the duration of the appointment based on the start and end dates.
   * The duration is calculated in days, hours, and minutes, and is stored in the `duration` property.
   *
   * @returns {void}
   */
  calculateDuration(): void {
    // Check if start and end dates are defined
    if (!this.appointment?.start || !this.appointment?.end) {
      // Reset the duration if either the start or end date is missing
      this.duration = '';

      return;
    }

    // Calculate the difference in milliseconds between the end and start times
    const diffInMs: number =
      this.appointment.end.getTime() - this.appointment.start.getTime();

    // Convert the difference from milliseconds to seconds, minutes, hours, and days
    const diffInSeconds: number = Math.floor(diffInMs / 1000);
    const diffInMinutes: number = Math.floor(diffInSeconds / 60);
    const diffInHours: number = Math.floor(diffInMinutes / 60);
    const diffInDays: number = Math.floor(diffInHours / 24);

    // Calculate the remaining hours and minutes after accounting for full days
    const hours: number = diffInHours % 24;
    const minutes: number = diffInMinutes % 60;

    // Initialize an array to hold the duration parts
    const durationParts: string[] = [];

    // Add the number of days to the duration parts if greater than 0
    if (diffInDays > 0) {
      const dayText =
        diffInDays === 1 ? 'calendarComponent.day' : 'calendarComponent.days';
      durationParts.push(`${diffInDays} ${this.translate.instant(dayText)}`);
    }

    // Add the number of hours to the duration parts if greater than 0
    if (hours > 0) {
      const hourText =
        hours === 1 ? 'calendarComponent.hour' : 'calendarComponent.hours';
      durationParts.push(`${hours} ${this.translate.instant(hourText)}`);
    }

    // Add the number of minutes to the duration parts if greater than 0
    if (minutes > 0) {
      const minuteText =
        minutes === 1
          ? 'calendarComponent.minute'
          : 'calendarComponent.minutes';
      durationParts.push(`${minutes} ${this.translate.instant(minuteText)}`);
    }

    // Join the duration parts with a comma and space, and assign to the duration property
    this.duration = durationParts.join(', ');
  }
}
