<ng-container *ngIf="init">{{smallText}}</ng-container>
<ng-container *ngIf="!init && !textExpanded"
  >{{midText}}
  <span
    *ngIf="text.length > midLength"
    class="text-primary text-sm font-normal font-italic"
    (click)="expandText($event)"
    >{{'general.showMore' | translate}}</span
  ></ng-container
>
<ng-container *ngIf="!init && textExpanded">
  {{ text }}
  <span
    *ngIf="text.length > midLength"
    class="text-primary text-sm font-normal font-italic"
    (click)="expandText($event)"
    >{{'general.showLess' | translate}}</span
  >
</ng-container>
