import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-expandable-text',
  templateUrl: './expandable-text.component.html',
  styleUrls: ['./expandable-text.component.scss']
})
export class ExpandableTextComponent implements OnInit {
  @Input({ required: true }) text = '';

  @Input() init = false;

  @Input() smallLength = 40;

  @Input() midLength = 100;

  smallText = '';

  midText = '';

  textExpanded = false;

  ngOnInit(): void {
    this.smallText = this.text.substring(0, this.smallLength);
    this.midText = this.text.substring(0, this.midLength);

    if (this.text.length > this.smallLength) {
      this.smallText += '...';
    }
    if (this.text.length > this.midLength) {
      this.midText += '...';
    }
  }

  expandText(event: Event): void {
    this.stopPropagation(event);
    this.textExpanded = !this.textExpanded;
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }
}
