<div style="position: relative; display: inline-block">
  <div class="p-link ml-4 mr-2 text-primary">
    <i
      class="pi pi-check-square"
      style="font-size: 1.5rem; padding: 0.5rem"></i>
  </div>
  <p-badge
    *ngIf="tasksChecked"
    [value]="tasks"
    [ngClass]="{'red': tasks>0, 'green': tasks===0}"
    severity="info"
    [style]="{'position': 'absolute', 'top': '-5px', 'right': '-5px'}">
  </p-badge>
</div>
