import { Component, OnInit, OnDestroy } from '@angular/core';
import { TicketService } from 'src/app/services/api/ticket.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-task-statistic',
  templateUrl: './task-statistic.component.html',
  styleUrl: './task-statistic.component.scss'
})
export class TaskStatisticComponent implements OnInit, OnDestroy {
  tasks = 0;

  subscription = new Subscription();

  tasksChecked = false;

  constructor(private readonly ticketService: TicketService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.ticketService.ticketChanged$.subscribe((changed) => {
        if (changed) {
          this.getTasks();
        }
      })
    );
    this.getTasks();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getTasks(): void {
    this.subscription.add(
      this.ticketService.getTasks().subscribe({
        next: (tasks) => {
          this.tasks = tasks ? tasks.length : 0;
          this.tasksChecked = true;
        },
        error: (err) => {
          console.error('Failed to fetch tasks', err);
          this.tasksChecked = false;
        }
      })
    );
  }
}
