<div [ngClass]="showHeader ? 'layout-topbar card p-2 mb-4' : 'layout-topbar'">
  <div class="topbar-start">
    <button
      #menubutton
      type="button"
      class="topbar-menubutton p-link p-trigger"
      (click)="onMenuButtonClick()">
      <i class="pi pi-bars"></i>
    </button>

    <app-breadcrumb class="topbar-breadcrumb"></app-breadcrumb>
  </div>

  <div class="topbar-end flex">
    <ul class="topbar-menu">
      <li [pTooltip]="'general.openTasks' | translate" tooltipPosition="left">
        <app-task-statistic></app-task-statistic>
      </li>
      <li class="topbar-profile">
        <button type="button" class="p-link" (click)="onProfileButtonClick()">
          <p-avatar
            *ngIf="!user?.profilePictureId"
            [label]="initials"
            shape="circle"></p-avatar>

          <p-avatar
            *ngIf="user?.profilePictureId"
            [image]="imgUrl"
            shape="circle"
            [style]="{ 'background-color': 'transparent' }"></p-avatar>
        </button>
      </li>
      <li>
        <app-config></app-config>
      </li>
    </ul>
  </div>
</div>
