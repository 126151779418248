import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { EChartsOption } from 'echarts';
import { Ticket, TicketCategory } from 'src/app/models/Ticket';
import { TicketService } from 'src/app/services/api/ticket.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-widget-tickets-all',
  templateUrl: './widget-tickets-all.component.html',
  styleUrl: './widget-tickets-all.component.scss'
})
export class WidgetTicketsAllComponent implements OnInit, OnChanges {
  @Input() preview = false;

  @Input() editMode = false;

  @Input() tickets: Ticket[] = [];

  @Input() colorScheme: 'dim' | 'light' | 'dark' = 'light';

  @Output() ticketCreated = new EventEmitter<void>();

  options!: EChartsOption;

  constructor(
    public readonly ticketService: TicketService,
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.generateChartData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tickets'] || changes['colorScheme']) {
      this.generateChartData();
    }
  }

  /**
   * Generates an array of the last twelve months' names translated to the current language.
   *
   * @returns {string[]} An array of month names for the last twelve months.
   */
  getLastTwelveMonths(): string[] {
    // Array of month names translated to the current language
    const months = [
      this.translate.instant('widgets.ticketAll.monthNames.January'),
      this.translate.instant('widgets.ticketAll.monthNames.February'),
      this.translate.instant('widgets.ticketAll.monthNames.March'),
      this.translate.instant('widgets.ticketAll.monthNames.April'),
      this.translate.instant('widgets.ticketAll.monthNames.May'),
      this.translate.instant('widgets.ticketAll.monthNames.June'),
      this.translate.instant('widgets.ticketAll.monthNames.July'),
      this.translate.instant('widgets.ticketAll.monthNames.August'),
      this.translate.instant('widgets.ticketAll.monthNames.September'),
      this.translate.instant('widgets.ticketAll.monthNames.October'),
      this.translate.instant('widgets.ticketAll.monthNames.November'),
      this.translate.instant('widgets.ticketAll.monthNames.December')
    ];

    // Get the current date
    const now = new Date();
    const labels = [];

    // Loop to get the last twelve months
    for (let index = 0; index < 12; index++) {
      // Calculate the month index for the current iteration
      const monthIndex = (now.getMonth() - index + 12) % 12;
      // Add the month name to the labels array
      labels.unshift(months[monthIndex]);
    }

    return labels;
  }

  /**
   * Calculates the number of tickets for each of the last twelve months and each category.
   *
   * @param {Ticket[]} tickets - Array of ticket objects.
   * @returns {{ [key: string]: number[] }} An object where keys are categories and values are arrays of ticket counts for the last twelve months.
   */
  getMonthlyTicketCountsByCategory(tickets: Ticket[]): {
    [key: string]: number[];
  } {
    const now = new Date();
    const categories = Object.values(TicketCategory);
    const monthlyCountsByCategory: { [key: string]: number[] } = {};

    // Initialize counts for each category
    categories.forEach((category) => {
      monthlyCountsByCategory[category] = Array(12).fill(0);
    });

    // Iterate through each ticket
    tickets.forEach((ticket) => {
      const ticketDate = new Date(ticket.createdAt);
      const monthDiff =
        // eslint-disable-next-line no-mixed-operators
        (now.getFullYear() - ticketDate.getFullYear()) * 12 +
        (now.getMonth() - ticketDate.getMonth());

      // Check if the ticket is within the last 12 months
      if (monthDiff >= 0 && monthDiff < 12) {
        const category = ticket.ticketCategoryType;
        // Increment the count for the corresponding category and month
        if (categories.includes(category)) {
          monthlyCountsByCategory[category][11 - monthDiff]++;
        }
      }
    });

    return monthlyCountsByCategory;
  }

  /**
   * Calculates the cumulative increase in the number of tickets for each of the last twelve months.
   *
   * @param {Ticket[]} tickets - Array of ticket objects.
   * @returns {number[]} An array representing the cumulative increase in ticket counts for the last twelve months.
   *                     Ensures that the value always increases or remains the same compared to the previous month.
   */
  calculateMonthlyTicketIncrease(tickets: Ticket[]): number[] {
    const now = new Date();
    const monthlyCounts: number[] = Array(12).fill(0);
    const cumulativeIncrease: number[] = Array(12).fill(0);

    // Iterate through each ticket to count tickets per month
    tickets.forEach((ticket) => {
      const ticketDate = new Date(ticket.createdAt);
      const monthDiff =
        // eslint-disable-next-line no-mixed-operators
        (now.getFullYear() - ticketDate.getFullYear()) * 12 +
        now.getMonth() -
        ticketDate.getMonth();

      // Check if the ticket is within the last 12 months
      if (monthDiff >= 0 && monthDiff < 12) {
        monthlyCounts[11 - monthDiff]++;
      }
    });

    // Initialize the first month's cumulative increase
    // eslint-disable-next-line prefer-destructuring
    cumulativeIncrease[0] = monthlyCounts[0];

    // Calculate the cumulative increase for each month
    for (let index = 1; index < monthlyCounts.length; index++) {
      cumulativeIncrease[index] =
        cumulativeIncrease[index - 1] + monthlyCounts[index];
    }

    return cumulativeIncrease;
  }

  /**
   * Generates chart data for the last twelve months, categorized by ticket types.
   * This method sets up the data and options for a stacked bar chart.
   *
   * @returns {void}
   */
  generateChartData(): void {
    // Calculate the date twelve months ago from today
    const now = new Date();
    const twelveMonthsAgo = new Date(
      now.getFullYear(),
      now.getMonth() - 12,
      now.getDate()
    );

    // Filter tickets to include only those created within the last twelve months
    const recentTickets = this.tickets.filter(
      (ticket) => new Date(ticket.createdAt) >= twelveMonthsAgo
    );

    // Get the monthly ticket counts categorized by ticket type
    const monthlyTicketCountsByCategory =
      this.getMonthlyTicketCountsByCategory(recentTickets);

    // Get the total monthly ticket counts
    const totalMonthlyTicketIncrease =
      this.calculateMonthlyTicketIncrease(recentTickets);

    // Set the options for the chart
    this.options = {
      tooltip: {
        trigger: 'axis',
        appendToBody: true,
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        top: 'center',
        left: 'left',
        orient: 'vertical',
        type: 'scroll',
        textStyle: {
          color:
            this.colorScheme === 'dark' || this.colorScheme === 'dim'
              ? '#fff'
              : '#000'
        },
        data: [
          this.translate.instant(
            'widgets.ticketAll.ticketCategories.Commissioning'
          ),
          this.translate.instant(
            'widgets.ticketAll.ticketCategories.Emergency'
          ),
          this.translate.instant(
            'widgets.ticketAll.ticketCategories.Malfunction'
          ),
          this.translate.instant('widgets.ticketAll.ticketCategories.Task'),
          this.translate.instant(
            'widgets.ticketAll.ticketCategories.Maintenance'
          ),
          this.translate.instant('widgets.ticketAll.ticketCategories.SGC')
        ]
      },
      grid: {
        left: '15%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: this.getLastTwelveMonths()
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: this.translate.instant(
            'widgets.ticketAll.ticketCategories.Commissioning'
          ),
          itemStyle: {
            color: 'rgba(96, 178, 87, 0.8)',
            borderColor: 'rgba(96, 178, 87)'
          },
          type: 'bar',
          stack: 'total',
          label: {
            show: true
          },
          emphasis: {
            focus: 'series'
          },
          data: monthlyTicketCountsByCategory['Commissioning']
        },
        {
          name: this.translate.instant(
            'widgets.ticketAll.ticketCategories.Emergency'
          ),
          itemStyle: {
            color: 'rgba(194, 98, 98, 0.8)',
            borderColor: 'rgba(194, 98, 98)'
          },
          type: 'bar',
          stack: 'total',
          label: {
            show: true
          },
          emphasis: {
            focus: 'series'
          },
          data: monthlyTicketCountsByCategory['Emergency']
        },
        {
          name: this.translate.instant(
            'widgets.ticketAll.ticketCategories.Malfunction'
          ),

          itemStyle: {
            color: 'rgba(194, 167, 77, 0.8)',
            borderColor: 'rgba(194, 167, 77)'
          },
          type: 'bar',
          stack: 'total',
          label: {
            show: true
          },
          emphasis: {
            focus: 'series'
          },
          data: monthlyTicketCountsByCategory['Malfunction']
        },
        {
          name: this.translate.instant(
            'widgets.ticketAll.ticketCategories.Task'
          ),
          itemStyle: {
            color: 'rgba(101,141,200, 0.8)',
            borderColor: 'rgba(101,141,200)'
          },
          type: 'bar',
          stack: 'total',
          label: {
            show: true
          },
          emphasis: {
            focus: 'series'
          },
          data: monthlyTicketCountsByCategory['Task']
        },
        {
          name: this.translate.instant(
            'widgets.ticketAll.ticketCategories.Maintenance'
          ),
          itemStyle: {
            color: 'rgba(155, 95, 196, 0.8)',
            borderColor: 'rgba(155, 95, 196)'
          },
          type: 'bar',
          stack: 'total',
          label: {
            show: true
          },
          emphasis: {
            focus: 'series'
          },
          data: monthlyTicketCountsByCategory['Maintenance']
        },
        {
          name: this.translate.instant(
            'widgets.ticketAll.ticketCategories.SGC'
          ),
          itemStyle: {
            color: 'rgb(242, 100, 247, 0.8)',
            borderColor: 'rgb(242, 100, 247)'
          },
          type: 'bar',
          stack: 'total',
          label: {
            show: true
          },
          emphasis: {
            focus: 'series'
          },
          data: monthlyTicketCountsByCategory['SGC']
        },

        {
          name: this.translate.instant('widgets.ticketAll.all'),
          type: 'line',
          smooth: true,
          tooltip: {
            valueFormatter(value) {
              return `${value as number}`;
            }
          },
          data: totalMonthlyTicketIncrease
        }
      ]
    };
  }

  viewAllTickets(): void {
    this.router.navigate(['tickets']);
  }

  newTicketCreated(): void {
    this.ticketCreated.emit();
  }

  canCreateTicket(): boolean {
    return this.authService.$can('create', 'Ticket');
  }
}
