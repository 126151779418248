<div [@fadeAnimation]>
  <div class="gridster-container" *ngIf="showGridster">
    <gridster
      [ngClass]="editMode ? 'edit-mode' : 'display-mode'"
      [options]="options">
      <ng-container *ngFor="let widget of dashboard">
        <gridster-item [item]="widget.item" [ngClass]="this.colorScheme">
          <!-- WIDGETS -->
          <span
            *ngIf="editMode"
            (click)="removeWidget(widget)"
            [pTooltip]="'widgets.general.remove' | translate"
            class="pi pi-trash text-red-500 mr-2 remove-widget"></span>
          <app-widget-ticket-emergency
            *ngIf="widget.component === 'app-widget-ticket-emergency' && tickets"
            [tickets]="tickets"
            [editMode]="editMode"
            [colorScheme]="colorScheme"
            class="widget"></app-widget-ticket-emergency>

          <app-widget-ticket-malfunction
            *ngIf="widget.component === 'app-widget-ticket-malfunction' && tickets"
            [tickets]="tickets"
            [editMode]="editMode"
            [colorScheme]="colorScheme"
            class="widget"></app-widget-ticket-malfunction>

          <app-widget-ticket-maintenance
            *ngIf="widget.component === 'app-widget-ticket-maintenance' && tickets"
            [tickets]="tickets"
            [editMode]="editMode"
            [colorScheme]="colorScheme"
            class="widget"></app-widget-ticket-maintenance>

          <app-widget-ticket-commissioning
            *ngIf="widget.component === 'app-widget-ticket-commissioning' && tickets"
            [tickets]="tickets"
            [editMode]="editMode"
            [colorScheme]="colorScheme"
            class="widget"></app-widget-ticket-commissioning>

          <app-widget-ticket-sgc
            *ngIf="widget.component === 'app-widget-ticket-sgc' && tickets"
            [tickets]="tickets"
            [editMode]="editMode"
            [colorScheme]="colorScheme"
            class="widget"></app-widget-ticket-sgc>

          <app-widget-ticket-task
            *ngIf="widget.component === 'app-widget-ticket-task' && tickets"
            [tickets]="tickets"
            [editMode]="editMode"
            [colorScheme]="colorScheme"
            class="widget"></app-widget-ticket-task>

          <app-widget-tickets-all
            *ngIf="widget.component === 'app-widget-tickets-all' && tickets"
            [tickets]="tickets"
            [editMode]="editMode"
            [colorScheme]="colorScheme"
            (ticketCreated)="ticketCreated()"
            class="widget"></app-widget-tickets-all>

          <app-ticket
            *ngIf="widget.component === 'app-tickets' && myTickets"
            [tickets]="myTickets"
            [standaloneCard]="false"></app-ticket>

          <!-- Todo: Add MyDay widget -->

          <div
            class="cursor-pointer empty-widget"
            (click)="showWidgetChooserModal(widget.id ? widget.id : 0)"
            *ngIf="widget.component === 'app-widget-empty' && tickets && editMode">
            <app-widget-empty class="widget"></app-widget-empty>
          </div>

          <!-- WIDGETS -->
        </gridster-item>
      </ng-container>
    </gridster>
  </div>

  <div
    *ngIf="!editMode"
    class="text-primary pl-3 pt-2 configure-dashboard"
    (click)="toggleEditMode()">
    <span class="pi pi-th-large"></span
    ><span class="ml-2">{{ 'widgets.general.configure' | translate }}</span>
  </div>

  <div class="flex gap-2 pt-1">
    <button
      *ngIf="editMode && $can('update')"
      pButton
      pRipple
      type="button"
      [label]="'widgets.general.save' | translate"
      class="p-button-outlined py-1"
      (click)="saveDashboard()"></button>
  </div>
  <p-dialog
    *ngIf="widgetChooserModalVisible && tickets"
    [draggable]="false"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    [(visible)]="widgetChooserModalVisible"
    (onHide)="hideWidgetChooserModal()"
    [modal]="true"
    [style]="{ width: '75%' }">
    <!-- Dialog Header -->
    <ng-template pTemplate="header">
      <h2>{{'widgets.general.addWidget'| translate }}</h2>
    </ng-template>

    <app-widget-chooser
      (selectedWidgetHasChanged)="selectedWidgetsChange($event)"
      [dashboard]="dashboard"
      [tickets]="tickets"></app-widget-chooser>
  </p-dialog>
</div>
