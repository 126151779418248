import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TaskStatisticComponent } from './task-statistic.component';
import { ButtonModule } from 'primeng/button';
import { BadgeModule } from 'primeng/badge';
@NgModule({
  declarations: [TaskStatisticComponent],
  imports: [
    CommonModule,
    SelectButtonModule,
    DropdownModule,
    FormsModule,
    ButtonModule,
    BadgeModule
  ],
  exports: [TaskStatisticComponent]
})
export class TaskStatisticModule {}
