import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DropdownChangeEvent } from 'primeng/dropdown';

import { GenericTableConfiguration } from 'src/app/models/GenericTableConfiguration';
import { FormType, HiveForm } from 'src/app/models/HiveForm';
import { Ticket } from 'src/app/models/Ticket';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import {
  displayLoggingContext,
  LoggingFormatter,
  LoggingFormatterBuilder
} from 'src/app/utils/logging';
import { AppAction } from 'src/config/authorization.config';

@Component({
  selector: 'app-ticket-form',
  templateUrl: './ticket-form.component.html',
  styleUrls: ['./ticket-form.component.scss']
})
export class TicketFormComponent implements OnInit {
  @Input() formGroupName!: string;

  @Input() ticket!: Ticket;

  @Input() hiveForm!: HiveForm;

  @Input() showTechnicianColumn = true;

  @Input() showCreatedAtColumn = false;

  @Input() deadlineEditable = false;

  @Input() showEditAction = true;

  @Input() showPdfAction = true;

  @Input() showDeleteAction = true;

  @Input() showCreateAction = true;

  @Input() showGlobalSearch = true;

  @Input() selectionDisabled = true;

  @Input() showStatusColumn = true;

  @Input() showCompletedAtColumn = true;

  @Input() showPermanentStatusColumn = false;

  @Input() showSelectedFormsOnly = false;

  @Input() isInAppointment = false;

  @Input() smallTable = false;

  @Input() isSelectable = false;

  @Input() users: User[] = [];

  @Input() selectedForms: HiveForm[] = [];

  @Input() currentAppointmentId: number | null = null;

  @Input() showTechnicianInSidebar = false;

  @Input() showDeadlineInSidebar = false;

  @Output() ticketChange = new EventEmitter<Ticket>();

  @Output() handleSelectedFormsChange = new EventEmitter<HiveForm[]>();

  @Output() handleFormEdited = new EventEmitter<HiveForm>();

  @Output() ticketUpdated = new EventEmitter<Ticket>();

  isSidebarVisible = false;

  formTypes = Object.values(FormType);

  createSubmitDisabled = true;

  loading = true;

  config = new GenericTableConfiguration({
    translationKey: 'formComponent'
  });

  loggingFormatter: LoggingFormatter | null = null;

  constructor(
    private readonly messageCenterService: MessageCenterService,
    private readonly translate: TranslateService,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    if (!this.hiveForm) {
      this.hiveForm = new HiveForm();
      if (
        this.ticket.customerDevices &&
        this.ticket.customerDevices.length === 1
      ) {
        [this.hiveForm.customerDevice] = this.ticket.customerDevices;
      }
    }

    this.loading = false;
    this.users = this.users.map((user) => ({
      ...user,
      filterOptionLabel: `${user.firstname} ${user.lastname}`
    }));

    this.loggingFormatter = LoggingFormatterBuilder.new<{
      [K in keyof HiveForm]: unknown;
    }>()
      .add('technicianId', displayLoggingContext)
      .add('customerDeviceId', displayLoggingContext)
      .add('type', (value) =>
        this.translate.instant(`formComponent.attributes.formTypes.${value}`)
      )
      .build();
  }

  handleCreateForm(): void {
    this.showTechnicianInSidebar = false;
    this.showDeadlineInSidebar = false;

    this.isSidebarVisible = true;
  }

  handleUpdateForm(form: HiveForm): void {
    if (this.isInAppointment) {
      this.showTechnicianInSidebar = true;
      this.showDeadlineInSidebar = true;
    }

    this.hiveForm = form;
    this.isSidebarVisible = true;
  }

  handleDeleteForm(form: HiveForm): void {
    if (this.ticket.forms) {
      this.ticket.forms = this.ticket.forms.filter(
        (formToDelete) => formToDelete.id !== form.id
      );
      this.ticketChange.emit(this.ticket);
    }
  }

  onFormTypeChange(event: DropdownChangeEvent): void {
    this.hiveForm.type = event.value;
    this.createSubmitDisabled = false;
  }

  onCustomerDeviceChange(event: DropdownChangeEvent): void {
    this.hiveForm.customerDevice = event.value;
    this.createSubmitDisabled = false;
  }

  onTechnicianChange(event: DropdownChangeEvent): void {
    this.hiveForm.technician = event.value;
    this.createSubmitDisabled = false;
  }

  onDeadlineChange(event: Date): void {
    this.hiveForm.deadline = event;
    this.createSubmitDisabled = false;
  }

  async onSidebarVisibleChange(value: boolean): Promise<void> {
    if (value === true) {
      this.isSidebarVisible = value;

      return;
    }

    const isVisible = await new Promise<boolean>((resolve) => {
      this.messageCenterService.confirm(
        this.translate.instant('general.confirmUnsavedChanges.header'),
        this.translate.instant('general.confirmUnsavedChanges.message'),
        () => {
          this.hiveForm = new HiveForm();
          resolve(false);
        },
        () => {
          resolve(true);
        }
      );
    });
    this.isSidebarVisible = isVisible;
  }

  onSubmit(): void {
    // Check if required fields are present
    if (!this.hiveForm.type || !this.hiveForm.customerDevice) {
      return;
    }

    // Set loading state to true
    this.loading = true;

    // Set the creation date of the form
    this.hiveForm.createdAt = new Date();

    // Check if the ticket already has forms
    if (this.ticket.forms) {
      // If the form has an ID, update the existing form
      if (this.hiveForm.id) {
        const index = this.ticket.forms.findIndex(
          (form) => form.id === this.hiveForm.id
        );
        if (index > -1) {
          // Update the existing form with new values
          this.ticket.forms[index].type = this.hiveForm.type;
          this.ticket.forms[index].customerDevice =
            this.hiveForm.customerDevice;
          this.ticket.forms[index].customerDeviceId =
            this.hiveForm.customerDevice.id;
          this.ticket.forms[index].technician = this.hiveForm.technician;
          this.ticket.forms[index].deadline = this.hiveForm.deadline;
        }
      } else {
        // If the form does not have an ID, add it as a new form
        this.ticket.forms.push(this.hiveForm);
      }
    } else {
      // Initialize the forms array and add the new form
      this.ticket.forms = [];
      this.ticket.forms.push(this.hiveForm);
    }

    // Emit the updated ticket
    this.ticketChange.emit(this.ticket);
    this.handleFormEdited.emit(this.hiveForm);
    this.selectedForms.push(this.hiveForm);

    // Hide the sidebar and reset the form
    this.isSidebarVisible = false;
    // This timeout is necessary to reload the table-data after the sidebar is closed
    setTimeout(() => {
      this.loading = false;
    }, 0);
    this.hiveForm = new HiveForm();

    if (
      this.ticket.customerDevices &&
      this.ticket.customerDevices.length === 1
    ) {
      [this.hiveForm.customerDevice] = this.ticket.customerDevices;
    }
  }

  onSelectedFormsChange(selectedForms: HiveForm[]): void {
    this.handleSelectedFormsChange.emit(selectedForms);
  }

  onDeadlineInlineChange(form: HiveForm): void {
    this.handleFormEdited.emit(form);
  }

  $can(action: AppAction): boolean {
    return this.authService.$can(action, 'Ticket');
  }

  onTicketUpdated(ticket: Ticket): void {
    this.ticketUpdated.emit(ticket);
  }
}
