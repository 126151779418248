import { getBackgroundColor, getIcon } from 'src/utils/ticket-helper';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Ticket } from 'src/app/models/Ticket';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { Address } from 'src/app/models/customer/Address';
import { TranslateService } from '@ngx-translate/core';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { Router } from '@angular/router';

export type HeaderStyle = {
  background: string;
  color: string;
};

@Component({
  selector: 'app-ticket-summary',
  templateUrl: './ticket-summary.component.html',
  styleUrl: './ticket-summary.component.scss'
})
export class TicketSummaryComponent implements OnInit, OnDestroy {
  @Input({ required: true }) ticket!: Ticket;

  @Input({ required: true }) expanded!: boolean;

  style!: HeaderStyle;

  colorScheme: 'dim' | 'light' | 'dark' = 'light';

  localStorageSubscription = new Subscription();

  constructor(
    private localStorageService: LocalStorageService,
    private readonly messageCenterService: MessageCenterService,
    private readonly translate: TranslateService,
    private clipboard: Clipboard,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.localStorageSubscription =
      this.localStorageService.itemValue.subscribe((value: string) => {
        if (value) {
          const { colorScheme } = JSON.parse(value);
          if (colorScheme) {
            this.colorScheme = colorScheme;
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.localStorageSubscription.unsubscribe();
  }

  get backgroundColor(): string {
    return getBackgroundColor(this.ticket);
  }

  get icon(): string {
    return getIcon(this.ticket);
  }

  formatAddress(address: Address, returnType?: 'street' | 'postal'): string {
    if (returnType === 'street') {
      return `${address.street}`;
    }

    if (returnType === 'postal') {
      return `${address.postalCode} ${address.city}`;
    }

    if (!returnType) {
      return `${address.street}, ${address.postalCode} ${address.city}`;
    }

    // Default fallback in case of unexpected input
    return '';
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  preventClick(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  openGoogleMaps(event: Event, openTab: boolean): void {
    this.preventClick(event);

    const { customer } = this.ticket;
    if (customer) {
      const address = this.formatAddress(customer?.facilityAddress);
      const encodedAddress = encodeURIComponent(address);
      const googleMapsUrl = `https://www.google.com/maps?q=${encodedAddress}`;
      if (openTab) {
        window.open(googleMapsUrl, '_blank');
      } else {
        this.clipboard.copy(address);

        this.messageCenterService.showToast(
          this.translate.instant(
            'calendarComponent.actions.toasts.copyLink.success.summary'
          ),
          this.translate.instant(
            'calendarComponent.actions.toasts.copyLink.success.detail'
          ),
          'success'
        );
      }
    }
  }
}
