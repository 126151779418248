import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterMetadata, TableState } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { FilterComponent } from 'src/app/components/misc/generic-table/filter/filter.component';
import { Country } from 'src/app/models/Country';
import { Address } from 'src/app/models/customer/Address';

import { Customer } from 'src/app/models/customer/Customer';
import { CustomerDevice } from 'src/app/models/customer/CustomerDevice';
import { TableFilter } from 'src/app/models/TableFilter';
import { AuthService } from 'src/app/services/auth/auth.service';
import {
  clearTableMemoryFilters,
  hasActiveFilters
} from 'src/app/utils/local-storage/table-memory.utils';
import { AppAction } from 'src/config/authorization.config';
import { environment } from 'src/environments/environment';

type VirtualTableCustomer = Customer & {
  virtual: {
    facilityAddress: string;
    customer: string;
    contactPerson: string;
    deviceCount: number;
    ticketCount: number;
    deviceSerialNumbers: string;
    facilityAddressType: string;
    isGasSeparatorInstalled: string;

    virtualSearch: {
      facilityAddressSearch: string;
      heatingEngineerSearch: string;
      deviceSearch: string;
    };

    virtualData: {
      gasSeparatorCheckDevices: CustomerDevice[];
      heatingEngineers: {
        id: number;
        name: string;
        deviceSerialNumbers: string;
      }[];

      devices: {
        id: number;
        deviceName: string;
        serialNumbers: string;
      }[];
    };
  };
};

type VirtualTableCustomerDeviceData =
  VirtualTableCustomer['virtual']['virtualData']['devices'][number];

@Component({
  selector: 'app-customer-table',
  templateUrl: './customer-table.component.html',
  styleUrls: ['./customer-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomerTableComponent
  implements OnChanges, OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('tableElement') public tableElement?: Table;

  @ViewChild('appFilter') appFilter?: FilterComponent;

  @Input({ required: true }) customers: Customer[] = [];

  @Input({ required: true }) countries: Country[] = [];

  @Input() isLoading = false;

  @Input() showEditAction = true;

  @Input() showDeleteAction = true;

  @Input() showCorrespondenceAction = true;

  @Input() showTicketAction = true;

  @Input() showCreateAction = true;

  @Input() showChooseAction = false;

  @Input() customerBadgeClickable = true;

  @Output() handleCreateCustomer = new EventEmitter<void>();

  @Output() handleUpdateCustomer = new EventEmitter<Customer>();

  @Output() handleDeleteCustomer = new EventEmitter<Customer>();

  @Output() handleViewCustomer = new EventEmitter<Customer>();

  @Output() handleNewCorrespondence = new EventEmitter<Customer>();

  @Output() handleNewTicket = new EventEmitter<Customer>();

  @Output() handleCustomerChosen = new EventEmitter<Customer>();

  @Output() handleChangeIsActive = new EventEmitter<{
    value: boolean;
    customer: Customer;
  }>();

  @Output() handleChangeIsBlocked = new EventEmitter<{
    value: boolean;
    customer: Customer;
  }>();

  @Output() handleReloadCustomers = new EventEmitter<void>();

  tableConfig = environment.tableConfiguration;

  virtualCustomers: VirtualTableCustomer[] = [];

  displayFilterOverlay = false;

  isFilterApplied = false;

  activeFilter: number | null = null;

  filterSubscription: Subscription | null = null;

  globalSearchValue = '';

  constructor(
    private readonly translate: TranslateService,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.updateVirtualCustomers();

    const localFilters = localStorage.getItem('customer-table-memory');
    this.isFilterApplied = hasActiveFilters('customer-table-memory');
    if (localFilters) {
      const tableState = JSON.parse(localFilters) as TableState;
      const filters = tableState.filters ?? {};
      if ('global' in filters) {
        this.globalSearchValue = (filters['global'] as FilterMetadata)
          .value as string;
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.tableElement) {
      this.filterSubscription = this.tableElement.onFilter.subscribe(
        (event) => {
          if (!event.filters) {
            this.isFilterApplied = false;

            return;
          }

          this.isFilterApplied = Object.keys(event.filters).some((x) => {
            if (x === 'global') {
              return true;
            }

            const filter = this.tableElement?.filters[x] as FilterMetadata;

            return (
              filter.value !== null &&
              filter.value !== undefined &&
              filter.value !== ''
            );
          });
        }
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['customers'] && changes['customers'].currentValue) {
      this.updateVirtualCustomers();
    }

    if (changes['countries'] && changes['countries'].currentValue) {
      this.updateVirtualCustomers();
    }
  }

  ngOnDestroy(): void {
    this.filterSubscription?.unsubscribe();
  }

  $can(action: AppAction): boolean {
    return this.authService.$can(action, 'Customer');
  }

  $canTicket(action: AppAction): boolean {
    return this.authService.$can(action, 'Ticket');
  }

  formatAddress(address: Address): string {
    const country = this.countries.find((c) => c.id === address.countryId);

    if (country) {
      const countryTranslation = this.translate.instant(
        `countries.${country.key}`
      );

      return `${address.street}, ${address.postalCode} ${address.city}, ${countryTranslation}`;
    }

    return `${address.street}, ${address.postalCode} ${address.city}`;
  }

  filterApplied(filter: TableFilter): void {
    this.activeFilter = filter.id;

    if (this.tableElement) {
      this.tableElement.filters = structuredClone(
        filter.filterData
      ) as Table['filters'];
      this.updateVirtualCustomers();
      this.tableElement._filter();
    }
  }

  clearFilters(): void {
    if (this.tableElement) {
      this.tableElement.clearFilterValues();
      this.tableElement.reset();
      clearTableMemoryFilters('customer-table-memory');
      this.isFilterApplied = false;
      this.globalSearchValue = '';
    }

    this.activeFilter = null;
  }

  saveFilterClicked(filterName: string): void {
    if (!this.tableElement) {
      throw new Error('Table element not found');
    }

    const tableFilter = new TableFilter();

    tableFilter.filterData = structuredClone(this.tableElement?.filters);
    tableFilter.table = 'customer';
    tableFilter.filterName = filterName;

    this.appFilter?.createFilter(tableFilter);
  }

  updateVirtualCustomers(): void {
    this.virtualCustomers = this.customers.map((customer) => {
      const facilityAddress = this.formatAddress(customer.facilityAddress);

      const deviceSerialNumbers =
        customer.customerDevices
          ?.reduce((arr, customerDevice) => {
            if (!customerDevice.isActive) {
              return arr;
            }

            if (customerDevice.internalDeviceSerialNumber) {
              arr.push(customerDevice.internalDeviceSerialNumber);
            }

            if (customerDevice.externalDeviceSerialNumber) {
              arr.push(customerDevice.externalDeviceSerialNumber);
            }

            return arr;
          }, [] as Array<string>)
          .join('; ') ?? '';

      const isGasSeparatorInstalled =
        customer.customerDevices?.reduce((text, customerDevice) => {
          if (!customerDevice.isActive) {
            return text;
          }

          const currentText =
            this.displayIsGasSeparatorInstalled(customerDevice);

          if (currentText === null) {
            return text;
          }

          return `${text}; ${currentText}`;
        }, '') ?? '';

      const heatingEngineers =
        customer.customerDevices?.reduce(
          (arr, customerDevice) => {
            if (!customerDevice.isActive) {
              return arr;
            }

            const index = arr.findIndex(
              (heatingEngineer) =>
                heatingEngineer.id === customerDevice.heatingEngineerId
            );

            if (index === -1) {
              arr.push({
                id: customerDevice.heatingEngineerId,
                name: customerDevice.heatingEngineer?.name ?? '',
                deviceSerialNumbers:
                  this.displaySerialNumber(customerDevice) ?? ''
              });

              return arr;
            }

            const serialNumber = this.displaySerialNumber(customerDevice);

            if (serialNumber) {
              arr[index].deviceSerialNumbers += `; ${serialNumber}`;
            }

            return arr;
          },
          [] as VirtualTableCustomer['virtual']['virtualData']['heatingEngineers']
        ) ?? [];

      const deviceData = this.getDevicesForTable(customer);

      return {
        ...customer,
        virtual: {
          facilityAddress,
          customer: `${customer.name} ${customer.customerNumber}`,
          contactPerson: this.displayContactPerson(customer),
          deviceCount: customer.customerDeviceCount ?? 0,
          ticketCount: customer.customerTicketCount ?? 0,
          facilityAddressType: this.translate.instant(
            `customerComponent.facilityAddressType.${customer.facilityAddressType}`
          ),
          deviceSerialNumbers,
          isGasSeparatorInstalled,
          virtualSearch: {
            facilityAddressSearch: `${this.translate.instant(
              `customerComponent.facilityAddressType.${customer.facilityAddressType}`
            )} ${facilityAddress}`,
            heatingEngineerSearch: heatingEngineers
              .map((x) => x.name)
              .join('; '),
            deviceSearch: deviceData.map((x) => x.deviceName).join('; ')
          },

          virtualData: {
            gasSeparatorCheckDevices:
              customer.customerDevices?.filter(
                (customerDevice) =>
                  customerDevice.device?.hasGasSeparatorCheck &&
                  customerDevice.isActive
              ) ?? [],

            heatingEngineers,
            devices: deviceData
          }
        }
      } satisfies VirtualTableCustomer;
    });
  }

  private getDevicesForTable(
    customer: Customer
  ): VirtualTableCustomerDeviceData[] {
    if (!customer.customerDevices) {
      return [];
    }

    return Array.from(
      customer.customerDevices
        .filter((customerDevice) => customerDevice.isActive)
        .reduce((deviceMap, customerDevice) => {
          const device = deviceMap.get(customerDevice.deviceId);

          if (device) {
            if (customerDevice.internalDeviceSerialNumber) {
              if (device.serialNumbers !== '') {
                device.serialNumbers += '; ';
              }

              device.serialNumbers += customerDevice.internalDeviceSerialNumber;
            }

            if (customerDevice.externalDeviceSerialNumber) {
              if (device.serialNumbers !== '') {
                device.serialNumbers += '; ';
              }

              device.serialNumbers += customerDevice.externalDeviceSerialNumber;
            }

            return deviceMap;
          }

          deviceMap.set(customerDevice.deviceId, {
            id: customerDevice.deviceId,
            deviceName: `${customerDevice.device!.title} (${customerDevice.device!.manufacturer.name})`,
            serialNumbers: this.displaySerialNumber(customerDevice) ?? ''
          });

          return deviceMap;
        }, new Map<number, VirtualTableCustomerDeviceData>())
        .values()
    );
  }

  displayContactPerson(customer: Customer): string {
    const mainContact = customer.contactPersons.find(
      (contactPerson) => contactPerson.isMainContact
    );

    if (!mainContact) {
      return '-';
    }

    if (mainContact.firstname) {
      return `${mainContact.firstname} ${mainContact.lastname}`;
    }

    return mainContact.lastname;
  }

  displaySerialNumber(customerDevice: CustomerDevice) {
    let serialNumber = customerDevice.internalDeviceSerialNumber ?? '';

    if (customerDevice.externalDeviceSerialNumber) {
      if (serialNumber) {
        serialNumber += '; ';
      }

      serialNumber += customerDevice.externalDeviceSerialNumber;
    }

    return serialNumber === '' ? undefined : serialNumber;
  }

  displayIsGasSeparatorInstalled(
    customerDevice: CustomerDevice
  ): string | null {
    if (customerDevice.device?.hasGasSeparatorCheck !== true) {
      return null;
    }

    return customerDevice.isGasSeparatorInstalled
      ? this.translate.instant('general.yes')
      : this.translate.instant('general.no');
  }

  createCustomer() {
    this.handleCreateCustomer.emit();
  }

  updateCustomer(customer: Customer) {
    this.handleUpdateCustomer.emit(customer);
  }

  deleteCustomer(customer: Customer) {
    this.handleDeleteCustomer.emit(customer);
  }

  viewCustomer(customer: Customer) {
    this.handleViewCustomer.emit(customer);
  }

  newCorrespondence(customer: Customer) {
    this.handleNewCorrespondence.emit(customer);
  }

  newTicket(customer: Customer) {
    this.handleNewTicket.emit(customer);
  }

  changeIsActive(value: boolean, customer: Customer) {
    this.handleChangeIsActive.emit({ value, customer });
  }

  changeIsBlocked(value: boolean, customer: Customer) {
    this.handleChangeIsBlocked.emit({ value, customer });
  }

  chooseCustomer(customer: Customer) {
    this.handleCustomerChosen.emit(customer);
  }

  reloadCustomers() {
    this.handleReloadCustomers.emit();
  }
}
