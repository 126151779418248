import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EChartsOption } from 'echarts';
import { Ticket, TicketCategory, TicketStatus } from 'src/app/models/Ticket';
import { TicketService } from 'src/app/services/api/ticket.service';

@Component({
  selector: 'app-widget-ticket-maintenance',
  templateUrl: './widget-ticket-maintenance.component.html',
  styleUrl: './widget-ticket-maintenance.component.scss'
})
export class WidgetTicketMaintenanceComponent implements OnInit, OnChanges {
  @Input() preview = false;

  @Input() editMode = false;

  @Input() tickets: Ticket[] = [];

  @Input() colorScheme: 'dim' | 'light' | 'dark' = 'light';

  options!: EChartsOption;

  constructor(
    public readonly ticketService: TicketService,
    private readonly translate: TranslateService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.filterTickets();
    this.generateChartData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tickets']) {
      this.filterTickets();
      this.generateChartData();
    }
    if (changes['colorScheme']) {
      this.generateChartData();
    }
  }

  /**
   * Filters the tickets to include only those that belong to the Maintenance category.
   *
   * @returns {void}
   */
  filterTickets(): void {
    this.tickets = this.tickets.filter(
      (ticket) => ticket.ticketCategoryType === TicketCategory.Maintenance
    );
  }

  /**
   * Generates chart data for ticket emergency statuses.
   * This method sets up the data and options for a bar chart.
   *
   * @returns { void }
   */
  generateChartData(): void {
    this.options = {
      tooltip: {
        trigger: 'item',
        appendToBody: true,
        position: 'right'
      },
      legend: {
        top: '5%',
        left: 'left',
        orient: 'vertical',
        type: 'scroll',
        textStyle: {
          color:
            this.colorScheme === 'dark' || this.colorScheme === 'dim'
              ? '#fff'
              : '#000'
        }
      },
      series: [
        {
          name: this.translate.instant('widgets.ticketMaintenance.count'),
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['65%', '50%'],
          avoidLabelOverlap: true,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            },
            label: {
              show: true,
              fontSize: 12,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.Planning
              ).length,
              name: this.translate.instant(
                'widgets.ticketTask.labels.Planning'
              ),
              itemStyle: {
                color: 'rgba(69, 168, 198, 0.8)',
                borderColor: 'rgb(69, 168, 198)'
              }
            },
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.Open
              ).length,
              name: this.translate.instant('widgets.ticketTask.labels.Open'),
              itemStyle: {
                color: 'rgba(0, 144, 255, 0.8)',
                borderColor: 'rgb(0, 144, 255)'
              }
            },
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.InProgress
              ).length,
              name: this.translate.instant(
                'widgets.ticketTask.labels.InProgress'
              ),
              itemStyle: {
                color: 'rgba(255, 187, 0, 0.8)',
                borderColor: 'rgb(255, 187, 0)'
              }
            },
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.Completed
              ).length,
              name: this.translate.instant(
                'widgets.ticketTask.labels.Completed'
              ),
              itemStyle: {
                color: 'rgba(50,205,50, 0.8)',
                borderColor: 'rgb(50,205,50)'
              }
            },
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.Cancelled
              ).length,
              name: this.translate.instant(
                'widgets.ticketTask.labels.Cancelled'
              ),
              itemStyle: {
                color: 'rgba(244, 67, 54, 0.8)',
                borderColor: 'rgb(244, 67, 54)'
              }
            },
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.Done
              ).length,
              name: this.translate.instant('widgets.ticketTask.labels.Done'),
              itemStyle: {
                color: 'rgba(173,255,47, 0.8)',
                borderColor: 'rgb(173,255,47)'
              }
            },
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.Complaint
              ).length,
              name: this.translate.instant(
                'widgets.ticketTask.labels.Complaint'
              ),
              itemStyle: {
                color: 'rgba(148, 18, 171, 0.8)',
                borderColor: 'rgb(148, 18, 171)'
              }
            },
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.Complained
              ).length,
              name: this.translate.instant(
                'widgets.ticketTask.labels.Complained'
              ),
              itemStyle: {
                color: 'rgba(160, 108, 233, 0.8)',
                borderColor: 'rgb(160, 108, 233)'
              }
            },
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.Goodwill
              ).length,
              name: this.translate.instant(
                'widgets.ticketTask.labels.Goodwill'
              ),
              itemStyle: {
                color: 'rgba(23, 121, 194, 0.8)',
                borderColor: 'rgb(23, 121, 194)'
              }
            },
            {
              value: this.tickets.filter(
                (ticket) =>
                  ticket.ticketStatusType === TicketStatus.GoodwillDone
              ).length,
              name: this.translate.instant(
                'widgets.ticketTask.labels.GoodwillDone'
              ),
              itemStyle: {
                color: 'rgba(17,84,135, 0.8)',
                borderColor: 'rgb(17,84,135)'
              }
            },
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.Paused
              ).length,
              name: this.translate.instant('widgets.ticketTask.labels.Paused'),
              itemStyle: {
                color: 'rgba(190, 134, 163, 0.8)',
                borderColor: 'rgb(190, 134, 163)'
              }
            },
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.Settle
              ).length,
              name: this.translate.instant('widgets.ticketTask.labels.Settle'),
              itemStyle: {
                color: 'rgba(0,77,0, 0.8)',
                borderColor: 'rgb(0,77,0)'
              }
            },
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.Settled
              ).length,
              name: this.translate.instant('widgets.ticketTask.labels.Settled'),
              itemStyle: {
                color: 'rgba(0,128,0, 0.8)',
                borderColor: 'rgb(0,128,0)'
              }
            }
          ]
        }
      ]
    };
  }

  /**
   * Navigates to the 'tickets' route to view all tickets.
   */
  viewAllTickets(): void {
    // Use the router to navigate to the 'tickets' route
    this.router.navigate(['tickets']);
  }
}
