<p-table
  #tableElement
  dataKey="id"
  [rows]="tableConfig.rows"
  [scrollHeight]="tableConfig.scrollHeight"
  [filterDelay]="1"
  [value]="virtualCustomerContactPerson"
  [paginator]="true"
  [showCurrentPageReport]="true"
  [currentPageReportTemplate]="'table.pageReportTemplate' | translate"
  [globalFilterFields]="['virtual.type', 'virtual.name', 'virtual.address', 'phoneNumberMobile', 'phoneNumberLandline', 'email', 'isActive', 'note']"
  [rowsPerPageOptions]="tableConfig.rowsPerPageOptions"
  [scrollable]="true">
  >
  <ng-template pTemplate="caption">
    <div class="flex relative align-items-center justify-content-between">
      <div>
        <span class="p-input-icon-left ml-auto">
          <i class="pi pi-search"></i>
          <input
            pInputText
            [value]="globalSearchValue"
            type="text"
            (input)="tableElement.filterGlobal($any($event.target).value, 'contains')"
            [placeholder]="'general.globalSearchPlaceholder' | translate" />
        </span>
      </div>
      <div *ngIf="tableConfig.showTableHeader" class="title-container">
        <h4>{{ "customerContactPersonComponent.table.title" | translate}}</h4>
      </div>
      <div *ngIf="$can('create')">
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-plus-circle"
          [label]="'customerContactPersonComponent.newCustomerContactPerson' | translate"
          class="flex-auto p-button-outlined"
          (click)="createCustomerContactPerson()"></button>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th
        pSortableColumn="virtual.type"
        class="white-space-nowrap"
        alignFrozen="left"
        pFrozenColumn>
        {{ 'customerContactPersonComponent.table.columns.type.title' |
        translate}}
        <p-sortIcon field="virtual.type" />
      </th>
      <th
        pSortableColumn="virtual.name"
        class="white-space-nowrap"
        alignFrozen="left"
        pFrozenColumn>
        {{ 'customerContactPersonComponent.table.columns.name.title' |
        translate}}
        <p-sortIcon field="virtual.name" />
      </th>
      <th pSortableColumn="address" class="white-space-nowrap">
        {{ 'customerContactPersonComponent.table.columns.address.title' |
        translate}}
        <p-sortIcon field="address" />
      </th>
      <th pSortableColumn="phoneNumberMobile" class="white-space-nowrap">
        {{ 'customerContactPersonComponent.table.columns.mobile.title' |
        translate}}
        <p-sortIcon field="phoneNumberMobile" />
      </th>
      <th pSortableColumn="phoneNumberLandline" class="white-space-nowrap">
        {{ 'customerContactPersonComponent.table.columns.landline.title' |
        translate}}
        <p-sortIcon field="phoneNumberLandline" />
      </th>
      <th pSortableColumn="email" class="white-space-nowrap">
        {{ 'customerContactPersonComponent.table.columns.email.title' |
        translate}}
        <p-sortIcon field="virtual.email" />
      </th>
      <th pSortableColumn="note" class="white-space-nowrap">
        {{ 'customerContactPersonComponent.table.columns.note.title' |
        translate}}
        <p-sortIcon field="note" />
      </th>
      <th pSortableColumn="isMainContact" class="white-space-nowrap">
        {{ 'customerContactPersonComponent.table.columns.isMainContact.title' |
        translate}}
        <p-sortIcon field="isMainContact" />
      </th>
      <th pSortableColumn="isActive" class="white-space-nowrap">
        {{ 'customerContactPersonComponent.table.columns.isActive.titleShort' |
        translate}}
        <p-sortIcon field="isActive" />
      </th>
      <th alignFrozen="right" pFrozenColumn>
        {{ 'customerContactPersonComponent.table.columns.actions.title' |
        translate}}
      </th>
    </tr>
    <tr>
      <th alignFrozen="left" pFrozenColumn>
        <p-columnFilter field="type" matchMode="contains" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
              [appendTo]="'body'"
              [ngModel]="value"
              [options]="typeOptions"
              [filter]="false"
              (onChange)="filter($event.value)">
              <ng-template let-option pTemplate="item">
                <div class="inline-block vertical-align-middle">
                  <span class="ml-1 mt-1">
                    {{'customerContactPersonComponent.attributes.type.enum.' +
                    option | translate}}
                  </span>
                </div>
              </ng-template>
              <ng-template let-options pTemplate="selectedItems">
                <div
                  class="inline-flex align-items-center gap-2 px-1"
                  *ngFor="let option of options; index as i">
                  <div>
                    {{'customerContactPersonComponent.attributes.type.enum.' +
                    option | translate}}{{ i !== options.length - 1 ? ',' : ''
                    }}
                  </div>
                </div>
                <div *ngIf="!options || options.length === 0">Select</div>
              </ng-template>
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th alignFrozen="left" pFrozenColumn>
        <p-columnFilter
          field="virtual.name"
          matchMode="contains"
          [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <input
              type="text"
              pInputText
              [ngModel]="value"
              (ngModelChange)="filter($event)"
              class="p-inputtext" />
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          field="virtual.address"
          matchMode="contains"
          [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <input
              type="text"
              pInputText
              [ngModel]="value"
              (ngModelChange)="filter($event)"
              class="p-inputtext" />
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          field="phoneNumberMobile"
          matchMode="contains"
          [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <input
              type="text"
              pInputText
              [ngModel]="value"
              (ngModelChange)="filter($event)"
              class="p-inputtext" />
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          field="phoneNumberLandline"
          matchMode="contains"
          [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <input
              type="text"
              pInputText
              [ngModel]="value"
              (ngModelChange)="filter($event)"
              class="p-inputtext" />
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="email" matchMode="contains" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <input
              type="text"
              pInputText
              [ngModel]="value"
              (ngModelChange)="filter($event)"
              class="p-inputtext" />
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="note" matchMode="contains" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <input
              type="text"
              pInputText
              [ngModel]="value"
              (ngModelChange)="filter($event)"
              class="p-inputtext" />
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="boolean"
          matchMode="equals"
          field="isMainContact" />
      </th>
      <th>
        <p-columnFilter type="boolean" matchMode="equals" field="isActive" />
      </th>
      <th alignFrozen="right" pFrozenColumn>
        <div class="flex gap-2">
          <button
            pButton
            pRipple
            [pTooltip]="'general.tooltips.manageFilters' | translate"
            tooltipPosition="left"
            (click)="filterManagement.toggle($event)"
            [outlined]="true"
            [style]="'border-radius: 50%; width: 2rem !important;height: 2rem!important;'"
            icon="pi pi-bars"></button>
          <button
            pButton
            pRipple
            [pTooltip]="'general.tooltips.saveFilters' | translate"
            tooltipPosition="left"
            [outlined]="true"
            [style]="'border-radius: 50%; width: 2rem !important;height: 2rem!important;'"
            (click)="filterCreate.toggle($event)"
            icon="pi pi-save"></button>
          <button
            pButton
            pRipple
            [pTooltip]="'general.tooltips.removeFilter' | translate"
            tooltipPosition="left"
            [style]="'border-radius: 50%; width: 2rem !important;height: 2rem!important;'"
            (click)="clearFilters()"
            [ngClass]="{'p-button-outlined': !isFilterApplied}"
            icon="pi pi-filter-slash"></button>
        </div>
        <p-overlayPanel
          #filterManagement
          (onShow)="displayFilterOverlay = $event">
          <app-filter
            #appFilter
            table="customer-contact-person"
            (filterApplied)="filterApplied($event)"></app-filter>
        </p-overlayPanel>
        <p-overlayPanel #filterCreate>
          <app-filter-create
            (saveFilterClicked)="saveFilterClicked($event);filterCreate.toggle($event)"></app-filter-create>
        </p-overlayPanel>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-customerContactPerson>
    <tr [ngClass]="{'deleted-data': customerContactPerson.deletedAt}">
      <td alignFrozen="left" pFrozenColumn>
        <span class="white-space-nowrap">
          {{ customerContactPerson.virtual.type }}
        </span>
      </td>
      <td alignFrozen="left" pFrozenColumn>
        <span class="white-space-nowrap">
          {{ customerContactPerson.virtual.name }}
        </span>
      </td>

      <td>
        <span class="white-space-nowrap">
          {{ customerContactPerson.virtual.address }}
        </span>
      </td>
      <td>
        <a
          class="link"
          [ngClass]="{'white-space-nowrap': true}"
          href="tel:{{customerContactPerson.phoneNumberMobile}}">
          <span class="white-space-nowrap">
            {{ customerContactPerson.phoneNumberMobile }}
          </span>
        </a>
      </td>
      <td>
        <a
          class="link"
          [ngClass]="{'white-space-nowrap': true}"
          href="tel:{{customerContactPerson.phoneNumberLandline}}">
          <span class="white-space-nowrap">
            {{ customerContactPerson.phoneNumberLandline }}
          </span>
        </a>
      </td>
      <td>
        <a
          class="link"
          [ngClass]="{'white-space-nowrap': true}"
          href="mailto:{{customerContactPerson.email}}">
          <span class="white-space-nowrap">
            {{ customerContactPerson.email }}
          </span>
        </a>
      </td>
      <td>
        <span [pTooltip]="customerContactPerson.note" class="cut-text">
          {{ customerContactPerson.note }}
        </span>
      </td>
      <td>
        <p-inputSwitch
          [disabled]="!$can('update') || customerContactPerson.isMainContact"
          [accessKey]="customerContactPerson.id + '-isMainContact'"
          [ngModel]="customerContactPerson.isMainContact"
          (ngModelChange)="changeIsMainContact($event, customerContactPerson)" />
      </td>
      <td>
        <p-inputSwitch
          [disabled]="!$can('update') || customerContactPerson.isMainContact"
          [accessKey]="customerContactPerson.id + '-isActive'"
          [ngModel]="customerContactPerson.isActive"
          (ngModelChange)="changeIsActive($event, customerContactPerson)" />
      </td>
      <td alignFrozen="right" pFrozenColumn>
        <div class="flex gap-2">
          <app-table-action
            *ngIf="$can('update') && showEditAction"
            (actionClicked)="updateCustomerContactPerson(customerContactPerson)"
            [icon]="'pi pi-pencil'"
            [severity]="'success'"
            [pTooltip]="'customerContactPersonComponent.actions.tooltips.edit' | translate"></app-table-action>

          <app-table-action
            *ngIf="$can('read') && !$can('update')"
            (actionClicked)="viewCustomerContactPerson(customerContactPerson)"
            [icon]="'pi pi-eye'"
            [severity]="'info'"
            [pTooltip]="'customerContactPersonComponent.actions.tooltips.view' | translate"></app-table-action>

          <app-table-action
            *ngIf="$can('delete') && showDeleteAction"
            (actionClicked)="deleteCustomerContactPerson(customerContactPerson)"
            [icon]="'pi pi-trash'"
            [severity]="'danger'"
            [pTooltip]="customerContactPerson.isMainContact ?
            ('customerContactPersonComponent.actions.tooltips.deleteNotPossible' | translate) :
            ('customerContactPersonComponent.actions.tooltips.delete' | translate)"></app-table-action>

          <app-table-action
            *ngIf="$can('read') && showChooseAction"
            (actionClicked)="chooseCustomerContactPerson(customerContactPerson)"
            [icon]="'pi pi-check-circle'"
            [severity]="'primary'"
            [pTooltip]="'customerComponent.actions.tooltips.choose' | translate"></app-table-action>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
