import { AuthService } from 'src/app/services/auth/auth.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { getPossibleTicketStatusTypesAccordingToWorkflow } from '../../utils/getPossibleTicketStatus';
import { ReoccurringInterval } from 'src/app/models/ReoccurringInterval';
import { Ticket, TicketCategory, TicketStatus } from 'src/app/models/Ticket';
import { User } from 'src/app/models/User';
import {
  categoryChips,
  reoccurring,
  statusChips
} from '../../utils/getTicketInfo';

@Component({
  selector: 'app-ticket-information',
  templateUrl: './ticket-information.component.html',
  styleUrls: ['./ticket-information.component.scss']
})
export class TicketInformationComponent implements OnInit {
  @Input() formGroupName!: string;

  @Input() editors: User[] = [];

  @Input() ticket!: Ticket;

  @Input() disabled = false;

  @Input() showDevices = false;

  @Input() showForm = false;

  @Input() isReoccurringTicket = false;

  @Output() ticketChange = new EventEmitter<Ticket>();

  @Output() showDevicesChange = new EventEmitter<boolean>();

  @Output() showFormChange = new EventEmitter<boolean>();

  @Output() isReoccurringTicketChange = new EventEmitter<boolean>();

  @Output() reoccurringInterval = new EventEmitter<ReoccurringInterval>();

  @Output() regenerateSubject = new EventEmitter<void>();

  editorsShadow: User[] = [];

  editorFilterValue = '';

  form!: FormGroup;

  showReoccurring = false;

  categoryChips = categoryChips;

  statusChips = statusChips;

  reoccurring: string[] = reoccurring;

  forms: string[] = [];

  selectedForms: string[] = [];

  selectedReoccurring: ReoccurringInterval = ReoccurringInterval.None;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.selectedReoccurring =
      this.ticket.reoccurringInterval || ReoccurringInterval.None;

    this.isReoccurringTicket =
      this.ticket.reoccurringInterval !== null &&
      this.ticket.reoccurringInterval !== ReoccurringInterval.None &&
      this.selectedReoccurring !== ReoccurringInterval.None;

    this.showDevices = Boolean(
      this.ticket.customerDevices && this.ticket.customerDevices.length
    );

    this.editors = this.editors.map((editor) => ({
      ...editor,
      filterOptionLabel: `${editor.firstname} ${editor.lastname}`
    }));

    this.getPossibleStatus();

    this.showReoccurring =
      this.ticket.ticketCategoryType === TicketCategory.SGC ||
      this.ticket.ticketCategoryType === TicketCategory.Maintenance;

    this.isReoccurringTicket = this.showReoccurring;
  }

  public getPossibleStatus(): void {
    const currentUser = this.authService.getLoggedInUser();

    this.statusChips = getPossibleTicketStatusTypesAccordingToWorkflow(
      this.ticket,
      currentUser
    );
  }

  editorChanged(editor: DropdownChangeEvent): void {
    this.form.get('editor')?.setValue(editor.value);
    this.onTicketChanged();
  }

  onTicketChanged(): void {
    this.ticket.editor = this.form.get('editor')?.value;
    this.ticket.ticketCategoryType = this.form.get('ticketCategory')?.value;
    if (!this.ticket.id) {
      this.showForm = Boolean(
        this.ticket.ticketCategoryType &&
          this.ticket.ticketCategoryType !== TicketCategory.Task
      );
    }
    this.ticket.ticketStatusType = this.form.get('ticketStatus')?.value;
    this.showReoccurring =
      this.ticket.ticketCategoryType === TicketCategory.SGC ||
      this.ticket.ticketCategoryType === TicketCategory.Maintenance;
    this.isReoccurringTicket = this.showReoccurring;

    this.ticketChange.emit(this.ticket);
  }

  onShowDevicesChange(): void {
    this.showDevicesChange.emit(this.showDevices);
  }

  onIsAppointmentNeededChange(): void {
    this.ticketChange.emit(this.ticket);
    this.form.markAsDirty();
  }

  onShowFormChange(): void {
    this.showFormChange.emit(this.showForm);
  }

  onIsReoccurringTicketChange(): void {
    this.ticket.reoccurringInterval = this.selectedReoccurring;

    this.reoccurringInterval.emit(
      this.selectedReoccurring as ReoccurringInterval
    );
    this.isReoccurringTicketChange.emit(this.isReoccurringTicket);
    this.ticketChange.emit(this.ticket);
    this.form.markAsDirty();
  }

  onExecutionDateChanged(executionDate: Date): void {
    this.changeStatusBasedOnExecutionDate(executionDate);
    this.ticketChange.emit(this.ticket);
    this.form.markAsDirty();
  }

  changeStatusBasedOnExecutionDate(executionDate: Date): void {
    let status: TicketStatus = 'Open';
    if (executionDate) {
      const currentDate = new Date();
      const threeMonthsFromNow = new Date();
      threeMonthsFromNow.setMonth(currentDate.getMonth() + 3);

      if (executionDate > threeMonthsFromNow) {
        status = 'Planning';
      }
    }
    this.ticket.ticketStatusType = status;
    this.form.get('ticketStatus')?.setValue(status);
  }

  resetEditors() {
    this.editors = this.editorsShadow;
    this.editorFilterValue = '';
  }

  editorFilter(event: Event) {
    this.editorFilterValue = (event.target as HTMLInputElement).value;
    this.editors = this.editorsShadow.filter((editor) => {
      const filterValue = this.editorFilterValue.toLowerCase();

      return (
        editor.firstname?.toLowerCase().includes(filterValue) ||
        editor.lastname?.toLowerCase().includes(filterValue)
      );
    });
  }

  onRegenerateSubject(): void {
    this.regenerateSubject.emit();
  }
}
