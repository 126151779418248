<div class="flex pb-2 w-full">
  <app-widget-wrapper
    size="medium"
    [widgetComponent]="WidgetComponent.TicketsAll"
    (widgetSelectedChange)="widgetSelectedChange($event)"
    [footerTitle]="'widgets.ticketAll.header' | translate">
    <app-widget-tickets-all
      [tickets]="tickets"
      [preview]="true"></app-widget-tickets-all>
  </app-widget-wrapper>
</div>
<div class="flex gap-2 pb-2 w-full">
  <app-widget-wrapper
    size="small"
    [widgetComponent]="WidgetComponent.TicketMaintenance"
    (widgetSelectedChange)="widgetSelectedChange($event)"
    [footerTitle]="'widgets.ticketMaintenance.header' | translate">
    <app-widget-ticket-maintenance
      [tickets]="tickets"
      [preview]="true"></app-widget-ticket-maintenance>
  </app-widget-wrapper>
  <app-widget-wrapper
    size="small"
    [widgetComponent]="WidgetComponent.TicketMalfunction"
    (widgetSelectedChange)="widgetSelectedChange($event)"
    [footerTitle]="'widgets.ticketMalfunction.header' | translate">
    <app-widget-ticket-malfunction
      [tickets]="tickets"
      [preview]="true"></app-widget-ticket-malfunction>
  </app-widget-wrapper>
  <app-widget-wrapper
    size="small"
    [widgetComponent]="WidgetComponent.TicketTask"
    (widgetSelectedChange)="widgetSelectedChange($event)"
    [footerTitle]="'widgets.ticketTask.header' | translate">
    <app-widget-ticket-task
      [tickets]="tickets"
      [preview]="true"></app-widget-ticket-task>
  </app-widget-wrapper>
</div>
<div class="flex gap-2 pb-2 w-full">
  <app-widget-wrapper
    size="small"
    [widgetComponent]="WidgetComponent.TicketEmergency"
    (widgetSelectedChange)="widgetSelectedChange($event)"
    [footerTitle]="'widgets.ticketEmergency.header' | translate">
    <app-widget-ticket-emergency
      [tickets]="tickets"
      [preview]="true"></app-widget-ticket-emergency>
  </app-widget-wrapper>
  <app-widget-wrapper
    size="small"
    [widgetComponent]="WidgetComponent.TicketCommissioning"
    (widgetSelectedChange)="widgetSelectedChange($event)"
    [footerTitle]="'widgets.ticketCommissioning.header' | translate">
    <app-widget-ticket-commissioning
      [tickets]="tickets"
      [preview]="true"></app-widget-ticket-commissioning>
  </app-widget-wrapper>
  <app-widget-wrapper
    size="small"
    [widgetComponent]="WidgetComponent.TicketSGC"
    (widgetSelectedChange)="widgetSelectedChange($event)"
    [footerTitle]="'widgets.ticketSGC.header' | translate">
    <app-widget-ticket-sgc
      [tickets]="tickets"
      [preview]="true"></app-widget-ticket-sgc>
  </app-widget-wrapper>
</div>
<div class="flex gap-2 pb-2 w-full">
  <app-widget-wrapper
    size="large"
    [widgetComponent]="WidgetComponent.Ticket"
    (widgetSelectedChange)="widgetSelectedChange($event)"
    [footerTitle]="'widgets.ticketCommissioning.header' | translate">
    <app-ticket [tickets]="tickets"></app-ticket>
  </app-widget-wrapper>
</div>
